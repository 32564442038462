import {
  Button,
  CircularProgress,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import {
  BusinessCenter,
  ChevronLeft,
  Done,
  FileCopy,
  MoreHoriz,
  History,
  EuroSymbol,
  Error,
  SpeakerNotes,
} from "@material-ui/icons";
import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "../../common/Components/Modal";

import { baseURL } from "../../../axios";

import {
  collectionActions,
  loadCollectionAttribute,
  updateAffaireLogisticien,
} from "../../../reducers/collectionsReducer";
import { buildRoute } from "../../../router/Tools";
import CheckboxInput from "../../common/Components/CheckboxInput";
import DateInput from "../../common/Components/DateInput";
import NumberInput from "../../common/Components/NumberInput";
import TextInput from "../../common/Components/TextInput";
import { hasRights } from "../../common/Tools/Tools";
import BonLivraisonBtn from "../BonLivraisonBtn";
import Documents from "../Documents";
import EmailButton from "../Email";

import Etablissement from "../Etablissement";
import FactureAnnexe from "../FactureAnnexe";
import Lot from "../Lot";
import AffaireCss from "./css/AffaireCss";

import { axiosClient } from "../../../axios";
import moment from "moment";
import NonConformite from "../NonConformite";
import TextArea from "../../common/Components/TextArea";

class Detail extends Component {
  state = {
    previousAffaireFromStore: null,
    affaire: null,
    modified: false,
    hide_facture_annexe: false,
    etablissements_loaded: false,
    unmountBonLivraison: false,
    isFromTrash: false,
    disabled: {
      prix_transport_achat: false,
      facture_transporteur: false,
      facture_fournisseur: false,
      facture_client: false,
      bouton_enlevement: false,
    },
    reset: {},
    lockedDates: {},
    list_campagnes_taf: [],
    etat_attente_facturation: null,
    fakeDocumentListrender: 0,
    openConfirmationCamionPasseModal: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { affairesStore, etatsStore } = nextProps;

    // Ici, on répond à la question: doit-on "changer" d'affaire ? ("le
    // store a changé d'affaire ? alors refresh le state")
    // Pour détecter cette situation, on conserver l'affaire reçue précédement
    // du store pour la comparer à chaque fois. (previousAffaireFromStore)
    if (
      (!prevState.affaire && affairesStore.detail && etatsStore.list) || // premier chargement
      (affairesStore.detail &&
        affairesStore.detail !== prevState.previousAffaireFromStore &&
        etatsStore.list) // changements suivants
    ) {
      if (!prevState.etablissements_loaded) {
        collectionActions(nextProps.dispatch, "etablissements", "INDEX", {
          params: {
            entreprise_uuids: ["fournisseur_uuid", "client_uuid"].map(
              (k) => affairesStore.detail[k]
            ),
          },
        });
      }

      return {
        previousAffaireFromStore: affairesStore.detail,
        affaire: affairesStore.detail,
        etablissements_loaded: true,
        defaultValues: {
          prix_transport_achat: affairesStore.detail.prix_transport_achat,
          prix_transport_achat_forfait:
            affairesStore.detail.prix_transport_achat_forfait,
          facture_transporteur: affairesStore.detail.facture_transporteur,
        },
        etat_attente_facturation: etatsStore.list.find(
          (e) => e.attente_facturation === true
        ),
        disabled: {
          facture_transporteur:
            affairesStore.detail.disabled_facture_transporteur,
          facture_fournisseur:
            affairesStore.detail.disabled_facture_fournisseur,
          facture_client: affairesStore.detail.disabled_facture_client,
          bouton_enlevement: affairesStore.detail.type_achat_rendu,
        },
      };
    }
    return null;
  }

  componentDidMount() {
    let { dispatch } = this.props;

    collectionActions(
      dispatch,
      "affaires",
      "SHOW",
      {
        uuid: this.props.match.params.uuid,
      },
      () => {
        this.getLockedDates();
        this.updateCampagneTafList();
        setTimeout(() => {
          this.getLockedDates();
          this.setIndexationCalc(null);
          this.updateCaseStoackageList();
        }, 500);
      }
    );

    // Partie des options pour les formulaires
    loadCollectionAttribute(
      this.props.dispatch,
      "list",
      "etats",
      this.props.etatsStore
    );

    loadCollectionAttribute(
      dispatch,
      "list",
      "logisticiens",
      this.props.logisticiensStore
    );

    loadCollectionAttribute(
      this.props.dispatch,
      "list",
      "articles",
      this.props.articlesStore
    );

    loadCollectionAttribute(
      this.props.dispatch,
      "list",
      "transports",
      this.props.transportsStore
    );
  }

  componentWillUnmount() {
    let { dispatch } = this.props;
    collectionActions(dispatch, "affaires", "RESET_ERRORS");
    collectionActions(dispatch, "affaires", "RESET_DETAIL");
    collectionActions(dispatch, "etablissements", "RESET_LIST");
    collectionActions(dispatch, "entreprises", "RESET_LIST");
  }

  /**
   * Retourne à la page précédente lors d'un clic sur le bouton Retour.
   */
  onClickReturnHandler() {
    this.props.history.push(buildRoute("/affaires"));
  }

  /**
   * Permet de charger les sites suite au changement dans le formulaire d'autocomplete
   * @param  {[type]} entreprise_uuids [description]
   * @return {[type]}                  [description]
   */
  getSitesFromUuids(entreprise_uuids) {
    collectionActions(this.props.dispatch, "etablissements", "INDEX", {
      params: { entreprise_uuids },
    });
  }

  getLockedDates() {
    const { affaire } = this.state;
    if (!affaire || !affaire.lieu_livraison_uuid) return;

    // if (affaire.need_check_blocage_planning_day) {
      axiosClient
        .get(`flux/getLockedDates`, {
          params: {
            site_enlevement_uuid: affaire.lieu_enlevement_uuid,
            site_livraison_uuid: affaire.lieu_livraison_uuid,
          },
        })
        .then((response) => {
          this.setState({ lockedDates: response.data });
        });
    // }
  }

  /**
   * Met à jour l'Affaire suite à une modification dans la section Affaire.
   *
   * Recalcule les Totaux si les paramètres concernés sont modifiés.
   */
  onChangeAffaireHandler(name, event) {
    const { value } = event.target;
    // Permet de définir la valeur de la case a cocher "Facture transporteur reçu"
    function getFactureTransporteurValue(state) {
      // Si le champ modifié est "facture_transporteur"
      if (name === "facture_transporteur") return value;

      // S'il s'agit d'une vente en départ ou d'un achat en rendu
      if (value) {
        if (name === "type_vente_depart" || name === "type_achat_rendu") {
          return true;
        }
      }

      return state.affaire.facture_transporteur;
    }

    function sumPrixTransportAchat(affaire, name, value) {
      function parse(value) {
        const number = parseFloat(value);
        return isNaN(number) ? 0 : number;
      }
      switch (name) {
        case "prix_transport_achat":
          return value;
        case "prix_transport_achat_forfait":
          return parse(value);
        default:
      }
    }

    this.setState(
      {
        defaultValues: {
          prix_transport_achat:
            name === "prix_transport_achat" ||
            name === "prix_transport_achat_forfait"
              ? sumPrixTransportAchat(this.state.affaire, name, value)
              : this.state.defaultValues.prix_transport_achat,
          prix_transport_achat_forfait:
            name === "prix_transport_achat_forfait"
              ? value
              : this.state.defaultValues.prix_transport_achat_forfait,
          facture_transporteur:
            name === "facture_transporteur"
              ? value
              : this.state.defaultValues.facture_transporteur,
        },
        affaire: {
          ...this.state.affaire,
          [name]: value,
          type_vente_depart:
            name === "type_vente_depart"
              ? value
              : name === "type_achat_rendu" && value
              ? false
              : this.state.affaire.type_vente_depart,
          type_achat_rendu:
            name === "type_achat_rendu"
              ? value
              : name === "type_vente_depart" && value
              ? false
              : this.state.affaire.type_achat_rendu,
          prix_transport_achat:
            (name === "type_vente_depart" && value) ||
            (name === "type_achat_rendu" && value)
              ? "0.00"
              : name === "prix_transport_achat" ||
                name === "prix_transport_achat_forfait"
              ? sumPrixTransportAchat(this.state.affaire, name, value)
              : this.state.defaultValues.prix_transport_achat,
          prix_transport_achat_forfait:
            (name === "type_vente_depart" && value) ||
            (name === "type_achat_rendu" && value)
              ? "0.00"
              : name === "prix_transport_achat_forfait"
              ? value
              : this.state.defaultValues.prix_transport_achat_forfait,
          facture_transporteur: getFactureTransporteurValue(this.state),
          poids_estime: this.getPoidsEstimeValue(name, value),
        },
        modified: true,
      },
      () => {
        this.setState(
          {
            disabled: {
              facture_transporteur:
                (name === "type_vente_depart" && value) ||
                (name === "type_achat_rendu" && value) ||
                this.state.affaire.type_vente_depart ||
                this.state.affaire.type_achat_rendu,
              bouton_enlevement:
                (name === "type_achat_rendu" && value) ||
                this.state.affaire.type_achat_rendu,
            },
            affaire: {
              ...this.state.affaire,
              indexation_gazoil_pourcentage:
                name === "indexation_gazoil_montant"
                  ? null
                  : this.state.affaire.indexation_gazoil_pourcentage,
              indexation_gazoil_montant:
                name === "indexation_gazoil_pourcentage"
                  ? null
                  : this.state.affaire.indexation_gazoil_montant,
            },
          },
          () => {
            this.setIndexationCalc(() => {
              if (
                !this.state.affaire.type_taf &&
                this.state.affaire.campagne_taf_uuid
              ) {
                this.setState(
                  {
                    affaire: {
                      ...this.state.affaire,
                      campagne_taf_uuid: null,
                    },
                  },
                  () => {
                    this.setIndexationCalc();
                  }
                );
              }

              const entreprises_fields = ["fournisseur_uuid", "client_uuid"];
              if (
                entreprises_fields.includes(name) &&
                this.state.affaire[name]
              ) {
                this.getSitesFromUuids(
                  entreprises_fields.map((k) => this.state.affaire[k])
                );
              }

              if (
                name === "lieu_livraison_uuid" ||
                name === "lieu_enlevement_uuid"
              ) {
                this.getLockedDates();
                this.updateCampagneTafList();
              }
            });
          }
        );
      }
    );
  }

  setIndexationCalc(cb) {
    if (!this.state.affaire) return;
    const {
      prix_transport_achat_forfait,
      indexation_gazoil_pourcentage,
      indexation_gazoil_montant,
    } = this.state.affaire;

    let local_affaire = null;
    if (!prix_transport_achat_forfait || prix_transport_achat_forfait === 0) {
      local_affaire = {
        ...this.state.affaire,
      };
    } else {
      local_affaire = {
        ...this.state.affaire,
        indexation_gazoil_pourcentage_calc:
          indexation_gazoil_pourcentage || !indexation_gazoil_montant
            ? null
            : Math.round(
                (indexation_gazoil_montant / prix_transport_achat_forfait) *
                  100 *
                  100
              ) / 100,
        indexation_gazoil_montant_calc:
          indexation_gazoil_montant || !indexation_gazoil_pourcentage
            ? null
            : Math.round(
                ((prix_transport_achat_forfait *
                  indexation_gazoil_pourcentage) /
                  100) *
                  100
              ) / 100,
      };
    }

    this.setState(
      {
        affaire: local_affaire,
      },
      cb
    );
  }

  updateCaseStoackageList() {
    if (!this.state.affaire) return;
    if (hasRights("read-case-stockage", this.props.user)) {
      loadCollectionAttribute(
        this.props.dispatch,
        "list",
        "caseStockage",
        this.props.caseStockageStore,
        {
          params: {
            site_uuid: this.state.affaire.lieu_livraison_uuid,
          },
        }
      );
    }
  }

  updateCampagneTafList() {
    if (!this.state.affaire) return;
    collectionActions(
      this.props.dispatch,
      "campagne-tafs",
      "INDEX_WITHOUT_DISPATCH",
      {
        params: {
          etablissements_uuid: [
            this.state.affaire.lieu_enlevement_uuid,
            this.state.affaire.lieu_livraison_uuid,
          ],
          campagne_taf_uuid: this.state.affaire.campagne_taf_uuid,
        },
      }
    ).then((response) => {
      this.setState({
        list_campagnes_taf: response.data,
      });
    });
  }

  getPoidsEstimeValue(name, value) {
    let affaire = Object.assign({}, this.state.affaire);
    if (name === "etat_uuid") {
      if (value === this.state.etat_attente_facturation.uuid) {
        affaire.poids_estime = this.state.affaire.poids_total_achat;
        this.setState({
          affaire: affaire,
        });
      }
    } else if (
      name === "poids_estime" &&
      this.state.etat_uuid !== this.state.etat_attente_facturation.uuid
    ) {
      affaire.poids_estime = value;
    } else {
      affaire.poids_estime = this.state.affaire.poids_estime;
    }

    return affaire.poids_estime;
  }

  onChangeAffaireAndSubmitHandler(name, event) {
    const { value } = event.target;
    this.setState(
      {
        affaire: {
          ...this.state.affaire,
          [name]: value,
        },
      },
      () => {
        this.onClickUpdateAffaireHandler();
      }
    );
  }

  /**
   * Recharge l'Affaire suite à sa mise à jour.
   *
   * @param {object} updated - L'Affaire reçue après mise à jour.
   */
  updateAffaire(updated) {
    if (updated) {
      this.setState(
        {
          affaire: updated,
          modified: false,
          hide_facture_annexe: false,
          unmountBonLivraison: false,
        },
        () => {
          this.setIndexationCalc(null);
        }
      );
      collectionActions(this.props.dispatch, "affaires", "RESET_ERRORS");
    }
  }

  /**
   * Met à jour l'Affaire lors d'un clic sur le bouton Valider dans le
   * formulaire des champs propres de l'Affaire.
   */
  onClickUpdateAffaireHandler() {
    const { dispatch } = this.props;
    const { affaire } = this.state;

    this.setState({
      hide_facture_annexe: true,
    });
    collectionActions(
      dispatch,
      "affaires",
      "UPDATE",
      affaire,
      this.updateAffaire.bind(this)
    );
  }

  /**
   * Met à jour l'Affaire lors d'un appui sur la touche ENTER dans le
   * formulaire des champs propres de l'Affaire.
   */
  onKeyPressAffaireHandler(event) {
    if (event.which === 13 || event.keyCode === 13 || event.key === "Enter") {
      this.onClickUpdateAffaireHandler();
    }
  }

  /**
   * Retourne le markup pour la liste des États d'une Affaire.
   */
  getEtats() {
    if (!this.props.etatsStore.list) {
      return [];
    }
    return this.props.etatsStore.list.map((etat) => {
      return (
        <MenuItem value={etat.uuid} key={etat.uuid}>
          {etat.nom}
        </MenuItem>
      );
    });
  }

  /**
   * Retourne la liste des cases.
   */
  getCaseStockages() {
    if (!this.props.caseStockageStore.list) {
      return [];
    }
    return this.props.caseStockageStore.list.map((caseItem) => {
      return (
        <MenuItem value={caseItem.uuid} key={caseItem.uuid}>
          {caseItem.reference}
        </MenuItem>
      );
    });
  }

  getCaseStockageInput() {
    const { affairesStore, classes, user } = this.props;
    if (!hasRights("read-case-stockage", user)) return null;

    const { affaire } = this.state;
    const hasEditRight =
      hasRights("admin-cud-affaires", user) &&
      hasRights("admin-cud-case-stockage", user);
    const is_from_trash = affaire.deleted_at ? true : false;

    return (
      <TextInput
        label="Case Stockage"
        className={classes.selectContainer}
        disabled={!hasEditRight || is_from_trash}
        margin="normal"
        collectionStore={affairesStore}
        name="stock_in_cases"
        onChangeHandler={this.onChangeAffaireHandler.bind(this)}
        onKeyPress={this.onKeyPressAffaireHandler.bind(this)}
        fullWidth
        SelectProps={{
          multiple: true,
          value: affaire.stock_in_cases,
        }}
        select
      >
        {this.getCaseStockages()}
      </TextInput>
    );
  }

  /**
   * Retourne le markup pour la liste des Transports.
   */
  getTransports() {
    if (!this.props.transportsStore.list) {
      return [];
    }
    return this.props.transportsStore.list.map((transport) => {
      return (
        <MenuItem value={transport.uuid} key={transport.uuid}>
          {transport.nom}
        </MenuItem>
      );
    });
  }

  /**
   * Retourne le markup pour la liste des Établissements (dits 'Sites') de
   * l'Entreprise.
   *
   * @param {string} entreprise_uuid - L'identifiant de l'Entreprise.
   */
  getEtablissements(entreprise_uuid) {
    if (!entreprise_uuid) return [];
    if (!this.props.etablissementsStore.list) {
      return [];
    }
    // Fait une 'shallow copy' pour ne pas modifier 'this.props'
    let etablissements = this.props.etablissementsStore.list.slice();
    if (entreprise_uuid) {
      etablissements = etablissements.filter(
        (e) => e.entreprise_uuid === entreprise_uuid
      );
    }
    return etablissements.map((etablissement) => {
      return (
        <MenuItem value={etablissement.uuid} key={etablissement.uuid}>
          {etablissement.nom}
        </MenuItem>
      );
    });
  }

  /**
   * Met en forme la date.
   *
   * @param {string} dateTime - La date au format ISO de la base.
   */
  formatDate(dateTime) {
    const options = { day: "numeric", month: "long", year: "numeric" };
    return new Date(dateTime).toLocaleDateString("fr-FR", options);
  }

  resetAutoComplete(name) {
    this.setState(
      {
        affaire: {
          ...this.state.affaire,
          [name]: null,
        },
        reset: {
          ...this.state.reset,
          [name]: true,
        },
      },
      () =>
        this.setState({
          reset: {
            ...this.state.reset,
            [name]: false,
          },
        })
    );
  }

  getIfHasProforma(affaire) {
    if (affaire.has_proforma) {
      return (
        <span title="Au moins un proformat ouvert">
          <EuroSymbol
            style={{ color: affaire.icon_color_has_proforma ?? "" }}
          />
        </span>
      );
    }
    return null;
  }

  getNonConformiteEtatMax(affaire) {
    const { classes } = this.props;

    if (affaire.non_conformites_etat_max) {
      return (
        <span title="'Présence d'une NC">
          <Error className={classes[affaire.non_conformites_etat_max]} />
        </span>
      );
    }
    return null;
  }

  /**
   * Retourne le markup pour la section Affaire.
   */
  getPartialDetail() {
    const { affairesStore, classes, user } = this.props;
    const { affaire } = this.state;
    const hasEditRight = hasRights("admin-cud-affaires", user);
    const hasExportLogRight =
      hasRights("export-affaires-logs", user) || user.is_admin;

    const logBtn =
      user && hasExportLogRight ? (
        <Button
          title="Exporter les logs"
          color="secondary"
          variant="contained"
          size="small"
          target="_blank"
          href={
            baseURL +
            buildRoute("/logs?affaire_uuid=:uuid", { uuid: affaire.uuid })
          }
        >
          <History className={classes.buttonIcon} /> Exporter les logs
        </Button>
      ) : null;

    const submitBtn = hasEditRight ? (
      <Grid item xs={12} className={classes.section}>
        {logBtn}
        <Button
          title="Valider les modifications"
          color="primary"
          variant="contained"
          size="small"
          disabled={affaire.deleted_at}
          onClick={this.onClickUpdateAffaireHandler.bind(this)}
          className={classes.doneButton}
        >
          <Done className={classes.buttonIcon} /> Valider
        </Button>
      </Grid>
    ) : null;

    const is_from_trash = affaire.deleted_at ? true : false;

    return (
      <>
        <Grid item xs={12}>
          <Typography
            variant="button"
            gutterBottom
            className={classes.crudTitle}
          >
            Affaire
          </Typography>
          <Button
            title="Retour à la liste des affaires"
            color="primary"
            className={classes.returnButton}
            onClick={this.onClickReturnHandler.bind(this, "/affaires")}
          >
            <ChevronLeft /> Retour
          </Button>
        </Grid>
        <Grid item xs={2} disabled>
          <CheckboxInput
            id="type_vente_depart"
            label="Vente en départ"
            value={affaire.type_vente_depart}
            disabled={!hasEditRight || is_from_trash}
            margin="normal"
            name="type_vente_depart"
            onChangeHandler={this.onChangeAffaireAndSubmitHandler.bind(this)}
          />
          <CheckboxInput
            id="type_achat_rendu"
            label="Achat en rendu"
            value={affaire.type_achat_rendu}
            disabled={!hasEditRight || is_from_trash}
            InputProps={{
              readOnly: true,
            }}
            margin="normal"
            name="type_achat_rendu"
            onChangeHandler={this.onChangeAffaireAndSubmitHandler.bind(this)}
          />
        </Grid>
        <Grid item xs={2}>
          <CheckboxInput
            id="type_pour_compte"
            label="Affaire pour compte"
            value={affaire.type_pour_compte}
            disabled={!hasEditRight || is_from_trash}
            margin="normal"
            name="type_pour_compte"
            onChangeHandler={this.onChangeAffaireAndSubmitHandler.bind(this)}
          />
          <CheckboxInput
            label="SEP"
            value={affaire.is_sep}
            margin="normal"
            disabled
          />
        </Grid>
        <Grid item xs={2}>
          <CheckboxInput
            id="type_taf"
            label="Affaire TAF"
            value={affaire.type_taf}
            margin="normal"
            name="type_taf"
            disabled={!hasEditRight || is_from_trash}
            onChangeHandler={this.onChangeAffaireAndSubmitHandler.bind(this)}
          />
          <CheckboxInput
            id="type_nc"
            label="Affaire NC expl"
            value={affaire.type_nc}
            margin="normal"
            name="type_nc"
            disabled={!hasEditRight || is_from_trash}
            onChangeHandler={this.onChangeAffaireAndSubmitHandler.bind(this)}
          />
        </Grid>
        <Grid container xs={1}>
          <Grid item xs={1}>
            <Typography
              variant="button"
              gutterBottom
              className={classes.crudTitle}
            >
              {affaire.type_affaire_libelle}
            </Typography>

            {this.getIfHasProforma(affaire)}
          </Grid>
        </Grid>

        <Grid item xs={2}>
          {this.getLogisticien()}
        </Grid>
        <Grid item xs={1} className={classes.commercialContainer}>
          <span>
            <BusinessCenter
              className={classes.transportIcon}
              fontSize={"small"}
            />
            {affaire.planning_commercial_initiales}
          </span>
        </Grid>
        {/* test */}
        <Grid item xs={2} disabled>
          <CheckboxInput
            id="is_affaire_commerciale"
            label="Affaire commerciale"
            value={affaire.is_affaire_commerciale}
            disabled
            margin="normal"
            name="is_affaire_commerciale"
          />
          <CheckboxInput
            id="is_mouvement_interne"
            label="Mouvement interne"
            value={affaire.is_mouvement_interne}
            disabled={!hasEditRight || is_from_trash}
            margin="normal"
            name="is_mouvement_interne"
            onChangeHandler={this.onChangeAffaireAndSubmitHandler.bind(this)}
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            id="reference_interne"
            label="Référence interne"
            value={affaire.reference_interne}
            disabled
            margin="normal"
            collectionStore={affairesStore}
            name="reference_interne"
            onChangeHandler={this.onChangeAffaireHandler.bind(this)}
            onKeyPress={this.onKeyPressAffaireHandler.bind(this)}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            className={classes.totauxDisabled}
            id="created_at"
            label="Date de création"
            disabled={true}
            value={this.formatDate(affaire.created_at)}
            margin="normal"
            collectionStore={affairesStore}
            name="created_at"
            onChangeHandler={this.onChangeAffaireHandler.bind(this)}
            onKeyPress={this.onKeyPressAffaireHandler.bind(this)}
            fullWidth
          />
        </Grid>
        <Grid item xs={3}>
          <TextInput
            id="etat_uuid"
            label="État"
            className={classes.selectContainer}
            value={affaire.etat_uuid}
            disabled={!hasEditRight || is_from_trash}
            margin="normal"
            collectionStore={affairesStore}
            name="etat_uuid"
            onChangeHandler={this.onChangeAffaireHandler.bind(this)}
            onKeyPress={this.onKeyPressAffaireHandler.bind(this)}
            fullWidth
            select
          >
            {this.getEtats()}
          </TextInput>
        </Grid>
        <Grid item xs={1} className={classes.ncIconContainer}>
          {this.getNonConformiteEtatMax(affaire)}
        </Grid>
        <Grid item xs={4}>
          <Grid container spacing={1} alignItems="flex-end">
            <Grid item xs={11}>
              <TextInput
                id="fournisseur_uuid"
                label="Fournisseur"
                className={classes.selectContainer}
                value={affaire.fournisseur_uuid}
                disabled={!hasEditRight || is_from_trash}
                margin="normal"
                collectionStore={affairesStore}
                name="fournisseur_uuid"
                onChangeHandler={this.onChangeAffaireHandler.bind(this)}
                onKeyPress={this.onKeyPressAffaireHandler.bind(this)}
                type="autocomplete"
                autocompleteProps={{
                  collectionName: "entreprises",
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={1} style={{ margin: "0 0 .5em", textAlign: "left" }}>
              <IconButton
                size="small"
                color="primary"
                disabled={!affaire.fournisseur_uuid}
                onClick={() =>
                  this.props.history.push(
                    buildRoute("/entreprises/:uuid", {
                      uuid: affaire.fournisseur_uuid,
                    })
                  )
                }
              >
                <MoreHoriz fontSize="inherit" />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <TextInput
            id="lieu_enlevement_uuid"
            label="Site d'enlèvement"
            className={classes.selectContainer}
            value={affaire.lieu_enlevement_uuid}
            disabled={!hasEditRight || is_from_trash}
            margin="normal"
            collectionStore={affairesStore}
            name="lieu_enlevement_uuid"
            onChangeHandler={this.onChangeAffaireHandler.bind(this)}
            onKeyPress={this.onKeyPressAffaireHandler.bind(this)}
            fullWidth
            select
          >
            {this.getEtablissements(affaire.fournisseur_uuid)}
          </TextInput>
        </Grid>
        <Grid item xs={4}>
          <DateInput
            id="date_enlevement"
            label="Date d'enlèvement"
            value={affaire.date_enlevement}
            disabled={!hasEditRight || is_from_trash}
            margin="normal"
            collectionStore={affairesStore}
            name="date_enlevement"
            onChangeHandler={this.onChangeAffaireHandler.bind(this)}
            onKeyPress={this.onKeyPressAffaireHandler.bind(this)}
            fullWidth
            pickertype="datetime"
            shouldDisableDate={(date) => {
              const date_formated = moment(date).format("YYYY-MM-DD");
              const locked_dates = this.state.lockedDates.enlevement
                ? this.state.lockedDates.enlevement
                : [];
              return (
                affaire.need_check_blocage_planning_day &&
                !hasRights("logistique-bypass-blocage-planning", user) &&
                locked_dates.includes(date_formated)
              );
            }}
            renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) => {
              const locked_dates = this.state.lockedDates.enlevement
                ? this.state.lockedDates.enlevement
                : [];
              const isLocked = locked_dates.includes(
                moment(day).format("YYYY-MM-DD")
              );
              const className = isLocked ? (!affaire.need_check_blocage_planning_day ? classes.dayLockedButSelectable : classes.dayLocked) : "";
              const title = isLocked ? "Date enlevement bloquée" : "";
              return (
                <div className={className} title={title}>
                  {dayComponent}
                </div>
              );
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <Grid container spacing={1} alignItems="flex-end">
            <Grid item xs={11}>
              <TextInput
                id="client_uuid"
                label="Client"
                className={classes.selectContainer}
                value={affaire.client_uuid}
                disabled={!hasEditRight || is_from_trash}
                margin="normal"
                collectionStore={affairesStore}
                name="client_uuid"
                onChangeHandler={this.onChangeAffaireHandler.bind(this)}
                onKeyPress={this.onKeyPressAffaireHandler.bind(this)}
                type="autocomplete"
                autocompleteProps={{
                  collectionName: "entreprises",
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={1} style={{ margin: "0 0 .5em", textAlign: "left" }}>
              <IconButton
                size="small"
                color="primary"
                disabled={!affaire.client_uuid}
                onClick={() =>
                  this.props.history.push(
                    buildRoute("/entreprises/:uuid", {
                      uuid: affaire.client_uuid,
                    })
                  )
                }
              >
                <MoreHoriz fontSize="inherit" />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <TextInput
            id="lieu_livraison_uuid"
            label="Site de livraison"
            className={classes.selectContainer}
            value={affaire.lieu_livraison_uuid}
            disabled={!hasEditRight || is_from_trash}
            margin="normal"
            collectionStore={affairesStore}
            name="lieu_livraison_uuid"
            onChangeHandler={this.onChangeAffaireHandler.bind(this)}
            onKeyPress={this.onKeyPressAffaireHandler.bind(this)}
            fullWidth
            select
          >
            {this.getEtablissements(affaire.client_uuid)}
          </TextInput>
        </Grid>
        <Grid item xs={4}>
          <DateInput
            id="date_livraison"
            label="Date de livraison"
            value={affaire.date_livraison}
            disabled={!hasEditRight || is_from_trash}
            margin="normal"
            collectionStore={affairesStore}
            name="date_livraison"
            onChangeHandler={this.onChangeAffaireHandler.bind(this)}
            onKeyPress={this.onKeyPressAffaireHandler.bind(this)}
            fullWidth
            pickertype="datetime"
            shouldDisableDate={(date) => {
              const date_formated = moment(date).format("YYYY-MM-DD");
              const locked_dates = this.state.lockedDates.livraison
                ? this.state.lockedDates.livraison
                : [];
              return (
                affaire.need_check_blocage_planning_day &&
                !hasRights("logistique-bypass-blocage-planning", user) &&
                locked_dates.includes(date_formated)
              );
            }}
            renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) => {
              const locked_dates = this.state.lockedDates.livraison
                ? this.state.lockedDates.livraison
                : [];
              const isLocked = locked_dates.includes(
                moment(day).format("YYYY-MM-DD")
              );
              const className = isLocked ? (!affaire.need_check_blocage_planning_day ? classes.dayLockedButSelectable : classes.dayLocked) : "";
              const title = isLocked ? "Date de livraison bloquée" : "";
              return (
                <div className={className} title={title}>
                  {dayComponent}
                </div>
              );
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            id="campagne_taf_uuid"
            label="Campagne TAF"
            value={affaire.campagne_taf_uuid}
            required={true}
            margin="normal"
            collectionStore={affairesStore}
            name="campagne_taf_uuid"
            disabled={!affaire.type_taf || !hasEditRight}
            onChangeHandler={this.onChangeAffaireHandler.bind(this)}
            onKeyPress={this.onKeyPressAffaireHandler.bind(this)}
            className={classes.selectContainer}
            fullWidth
            select
          >
            {this.getCampagnesTaf()}
          </TextInput>
        </Grid>
        <Grid item xs={8} />
        <Grid item xs={4}>
          <TextInput
            id="reference_client"
            label="Référence client"
            value={affaire.reference_client}
            disabled={!hasEditRight || is_from_trash}
            margin="normal"
            collectionStore={affairesStore}
            name="reference_client"
            onChangeHandler={this.onChangeAffaireHandler.bind(this)}
            onKeyPress={this.onKeyPressAffaireHandler.bind(this)}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            id="reference_fournisseur"
            label="Référence fournisseur"
            value={affaire.reference_fournisseur}
            disabled={!hasEditRight || is_from_trash}
            margin="normal"
            collectionStore={affairesStore}
            name="reference_fournisseur"
            onChangeHandler={this.onChangeAffaireHandler.bind(this)}
            onKeyPress={this.onKeyPressAffaireHandler.bind(this)}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <NumberInput
            id="poids_estime"
            label="Poids estimé (t)"
            value={affaire.poids_estime}
            disabled={!hasEditRight || is_from_trash}
            margin="normal"
            collectionStore={affairesStore}
            name="poids_estime"
            onChangeHandler={this.onChangeAffaireHandler.bind(this)}
            onKeyPress={this.onKeyPressAffaireHandler.bind(this)}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            id="transport_uuid"
            label="Transport"
            className={classes.selectContainer}
            value={affaire.transport_uuid}
            disabled={!hasEditRight || is_from_trash}
            margin="normal"
            collectionStore={affairesStore}
            name="transport_uuid"
            onChangeHandler={this.onChangeAffaireHandler.bind(this)}
            onKeyPress={this.onKeyPressAffaireHandler.bind(this)}
            fullWidth
            select
          >
            {this.getTransports()}
          </TextInput>
        </Grid>
        <Grid item xs={4}>
          <Grid container spacing={1} alignItems="flex-end">
            <Grid item xs={11}>
              <TextInput
                id="transporteur_uuid"
                label="Transporteur"
                className={classes.selectContainer}
                value={affaire.transporteur_uuid}
                disabled={!hasEditRight || is_from_trash}
                margin="normal"
                collectionStore={affairesStore}
                name="transporteur_uuid"
                onChangeHandler={this.onChangeAffaireHandler.bind(this)}
                onKeyPress={this.onKeyPressAffaireHandler.bind(this)}
                type="autocomplete"
                autocompleteProps={{
                  collectionName: "entreprises",
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={1} style={{ margin: "0 0 .5em", textAlign: "left" }}>
              <IconButton
                size="small"
                color="primary"
                disabled={!affaire.transporteur_uuid}
                onClick={() =>
                  this.props.history.push(
                    buildRoute("/entreprises/:uuid", {
                      uuid: affaire.transporteur_uuid,
                    })
                  )
                }
              >
                <MoreHoriz fontSize="inherit" />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          {this.getCaseStockageInput()}
        </Grid>
        <Grid item xs={4}>
          <NumberInput
            id="prix_transport_achat"
            label="Prix du transport à l'achat (€)"
            value={affaire.prix_transport_achat}
            disabled={true}
            margin="normal"
            collectionStore={affairesStore}
            name="prix_transport_achat"
            onChangeHandler={this.onChangeAffaireHandler.bind(this)}
            onKeyPress={this.onKeyPressAffaireHandler.bind(this)}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <NumberInput
            id="prix_transport_achat_forfait"
            label="Prix forfaitaire du transport à l'achat (€)"
            value={affaire.prix_transport_achat_forfait}
            disabled={!hasEditRight || is_from_trash}
            margin="normal"
            collectionStore={affairesStore}
            name="prix_transport_achat_forfait"
            onChangeHandler={this.onChangeAffaireHandler.bind(this)}
            onKeyPress={this.onKeyPressAffaireHandler.bind(this)}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <div style={{ display: "flex", height: "1rem" }}>
            <p
              style={{
                textAlign: "center",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <span>(% Indexation => € indexation)</span>
              <CheckboxInput
                id="indexation_gazoil_estime"
                label="Estimation indexation ?"
                value={affaire.indexation_gazoil_estime}
                disabled={!hasEditRight || is_from_trash}
                margin="normal"
                name="indexation_gazoil_estime"
                onChangeHandler={this.onChangeAffaireHandler.bind(this)}
              />
            </p>
          </div>
          <div style={{ display: "flex" }}>
            <NumberInput
              id="indexation_gazoil_pourcentage"
              value={affaire.indexation_gazoil_pourcentage}
              disabled={!hasEditRight || is_from_trash}
              margin="normal"
              collectionStore={affairesStore}
              name="indexation_gazoil_pourcentage"
              onChangeHandler={this.onChangeAffaireHandler.bind(this)}
              onKeyPress={this.onKeyPressAffaireHandler.bind(this)}
              fullWidth
            />
            <NumberInput
              id="indexation_gazoil_montant_calc"
              name="indexation_gazoil_montant_calc"
              value={affaire.indexation_gazoil_montant_calc}
              disabled
              margin="normal"
              collectionStore={affairesStore}
              fullWidth
            />
          </div>
          <div
            style={{
              display: "flex",
              height: "1rem",
              justifyContent: "space-between",
            }}
          >
            <p style={{ textAlign: "center" }}>(€ Indexation => % indexation</p>
          </div>
          <div style={{ display: "flex" }}>
            <NumberInput
              id="indexation_gazoil_montant"
              value={affaire.indexation_gazoil_montant}
              disabled={!hasEditRight || is_from_trash}
              margin="normal"
              collectionStore={affairesStore}
              name="indexation_gazoil_montant"
              onChangeHandler={this.onChangeAffaireHandler.bind(this)}
              onKeyPress={this.onKeyPressAffaireHandler.bind(this)}
              fullWidth
            />
            <NumberInput
              id="indexation_gazoil_pourcentage_calc"
              name="indexation_gazoil_pourcentage_calc"
              value={affaire.indexation_gazoil_pourcentage_calc}
              disabled
              margin="normal"
              collectionStore={affairesStore}
              fullWidth
            />
          </div>
        </Grid>
        <Grid item xs={4}>
          <NumberInput
            id="prix_transport_vente"
            label="Prix transport vente (€) (= refacturé au fournisseur)"
            value={affaire.prix_transport_vente}
            disabled={!hasEditRight || is_from_trash}
            margin="normal"
            collectionStore={affairesStore}
            name="prix_transport_vente"
            onChangeHandler={this.onChangeAffaireHandler.bind(this)}
            onKeyPress={this.onKeyPressAffaireHandler.bind(this)}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <NumberInput
            id="deduction_frais_collecte"
            label="Montant des frais de collecte sur bordereau d'achat (€)"
            value={affaire.deduction_frais_collecte}
            margin="normal"
            collectionStore={affairesStore}
            disabled={true}
            name="deduction_frais_collecte"
            onChangeHandler={this.onChangeAffaireHandler.bind(this)}
            onKeyPress={this.onKeyPressAffaireHandler.bind(this)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextArea
            id="suivi_logistique"
            label="Suivi Logistique"
            value={affaire.suivi_logistique}
            disabled={!hasEditRight || is_from_trash}
            name="suivi_logistique"
            onChangeHandler={this.onChangeAffaireHandler.bind(this)}
            collectionStore={affairesStore}
          />
        </Grid>
        <Grid item xs={12}>
          <TextArea
            id="commentaires"
            label="Commentaires Commercial"
            value={affaire.commentaires}
            disabled={!hasEditRight || is_from_trash}
            name="commentaires"
            onChangeHandler={this.onChangeAffaireHandler.bind(this)}
            collectionStore={affairesStore}
          />
        </Grid>
        <Grid item xs={12}>
          <TextArea
            id="commentaire_production"
            label="Commentaires Production"
            value={affaire.commentaire_production}
            disabled={!hasEditRight || is_from_trash}
            name="commentaire_production"
            onChangeHandler={this.onChangeAffaireHandler.bind(this)}
            collectionStore={affairesStore}
          />
        </Grid>
        <Grid item xs={12}>
          <TextArea
            id="facturation_commentaire"
            label="Commentaires Facturation"
            value={affaire.facturation_commentaire}
            disabled={!hasEditRight || is_from_trash}
            name="facturation_commentaire"
            onChangeHandler={this.onChangeAffaireHandler.bind(this)}
            collectionStore={affairesStore}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="meg_complement_reference"
            label="MEG Complement Référence"
            value={affaire.meg_complement_reference}
            disabled={!hasEditRight || is_from_trash}
            margin="normal"
            collectionStore={affairesStore}
            name="meg_complement_reference"
            onChangeHandler={this.onChangeAffaireHandler.bind(this)}
            onKeyPress={this.onKeyPressAffaireHandler.bind(this)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextArea
            id="meg_complement_description"
            label="MEG Complement Description"
            value={affaire.meg_complement_description}
            disabled={!hasEditRight || is_from_trash}
            name="meg_complement_description"
            onChangeHandler={this.onChangeAffaireHandler.bind(this)}
            collectionStore={affairesStore}
          />
        </Grid>
        <Grid item xs={4}>
          <CheckboxInput
            id="facture_fournisseur"
            label="Facture fournisseur reçue"
            value={affaire.facture_fournisseur}
            disabled={
              !hasEditRight ||
              is_from_trash ||
              this.state.disabled.facture_fournisseur
            }
            margin="normal"
            name="facture_fournisseur"
            onChangeHandler={this.onChangeAffaireHandler.bind(this)}
          />
        </Grid>
        <Grid item xs={4}>
          <CheckboxInput
            id="facture_transporteur"
            label="Facture transporteur reçue"
            value={affaire.facture_transporteur}
            disabled={
              !hasEditRight ||
              is_from_trash ||
              this.state.disabled.facture_transporteur
            }
            margin="normal"
            name="facture_transporteur"
            onChangeHandler={this.onChangeAffaireHandler.bind(this)}
          />
        </Grid>
        <Grid item xs={4}>
          <CheckboxInput
            id="facture_client"
            label="Facture client envoyée"
            value={affaire.facture_client}
            disabled={
              !hasEditRight ||
              is_from_trash ||
              this.state.disabled.facture_client
            }
            margin="normal"
            name="facture_client"
            onChangeHandler={this.onChangeAffaireHandler.bind(this)}
          />
        </Grid>
        <Grid item xs={4}>
          <CheckboxInput
            id="camion_passe"
            label="Camion Passé"
            value={affaire.camion_passe}
            disabled={!hasEditRight || is_from_trash}
            margin="normal"
            name="camion_passe"
            onChangeHandler={(name, e) => {
              this.handleCamionPasse(affaire, name, e);
            }}
          />
        </Grid>
        {submitBtn}
      </>
    );
  }

  getContentConfirmationCamionPasseModal() {
    const { classes } = this.props;

    return (
      <>
        <DialogTitle className={classes.modalTitle}>
          <SpeakerNotes className={classes.ico} /> Confirmation
        </DialogTitle>
        <DialogContent>
          <Typography>
            "Cocher la case "camion passé" sort les PL du stock. Êtes-vous sûr
            de vouloir confirmer le passage du camion ?
          </Typography>
        </DialogContent>
      </>
    );
  }

  handleCamionPasse(affaire, name, e) {
    if (affaire.packing_list_flux.length > 0) {
      this.setState({
        openConfirmationCamionPasseModal: true,
        affaire: affaire,
      });
    } else {
      this.onChangeAffaireHandler(name, e);
    }
  }

  onCloseConfirmationCamionPasseModal() {
    this.setState({
      openConfirmationCamionPasseModal: false,
    });
  }

  onSubmitConfirmationCamionPasseModal() {
    const { affaire } = this.state;
    this.setState({
      affaire: { ...this.state.affaire, camion_passe: !affaire.camion_passe },
    });

    this.onCloseConfirmationCamionPasseModal();
  }

  getCampagnesTaf() {
    if (this.state.list_campagnes_taf.length === 0) {
      return [
        <MenuItem value={null} key={null}>
          Aucune Campagne Compatible avec l'affaire
        </MenuItem>,
      ];
    }
    return this.state.list_campagnes_taf.map((campagne) => {
      return (
        <MenuItem value={campagne.uuid} key={campagne.uuid}>
          {campagne.nom}
        </MenuItem>
      );
    });
  }

  /**
   * Met à jour l'Affaire suite à l'envoi d'un Email.
   *
   * @param {object} sent - L'objet reçu à l'envoi de l'Email.
   */
  handleSent(sent) {
    const { affaire } = this.state;
    if (sent) {
      this.setState({
        affaire: {
          ...affaire,
          emails_rendered: {
            ...affaire.emails_rendered,
            [sent.type]: { ...sent },
          },
        },
        // eslint-disable-next-line
        fakeDocumentListrender: (this.state.fakeDocumentListrender += 1),
      });
    }
  }

  /**
   * Retourne un identifiant unique pour chaque Email.
   *
   * L'identifiant dépend du type (enlèvement, livraison, transport) et de la
   * date d'instanciation du modèle.
   */
  getEmailKey(type) {
    const { affaire } = this.state;
    return type + " " + affaire.rendered_at;
  }

  getFormEmailContrat(is_achat, data, onChangeHandler) {
    return (
      <>
        <Grid item xs={12}>
          <TextInput
            id="langue"
            name="langue"
            label="Langue"
            value={data.langue || "fr"}
            onChange={(e) => onChangeHandler("langue", e)}
            margin="normal"
            fullWidth
            collectionStore={{}}
            select
          >
            <MenuItem selected value="fr">
              Français
            </MenuItem>
            <MenuItem value="en">Anglais</MenuItem>
          </TextInput>
          <TextField
            id="commentaire"
            name="commentaire"
            label="Commentaire"
            value={data.commentaire}
            multiline
            rows="4"
            onChange={(e) => onChangeHandler("commentaire", e)}
            margin="normal"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <CheckboxInput
            id="with_prix_achat"
            name="with_prix_achat"
            label={is_achat ? "Avec prix d'achat" : "Avec prix de vente"}
            value={data.with_prix_achat}
            onChange={(e) =>
              onChangeHandler("with_prix_achat", {
                target: { value: e.target.checked },
              })
            }
            margin="normal"
            fullWidth
          />
        </Grid>
      </>
    );
  }

  getFormLivraison(data, onChangeHandler) {
    const formAnnexe7 = data.with_annexe
      ? this.getFormAnnexe7(data, onChangeHandler)
      : null;
    return (
      <>
        <Grid item xs={12}>
          <CheckboxInput
            id="with_annexe"
            name="with_annexe"
            label="Joindre l'annexe VII"
            value={data.with_annexe}
            onChange={(e) =>
              onChangeHandler("with_annexe", {
                target: { value: e.target.checked },
              })
            }
            margin="normal"
            fullWidth
          />
          {formAnnexe7}
        </Grid>
      </>
    );
  }

  getFormAnnexe7(data, onChangeHandler) {
    const formAnnexe7Detail = data.update_prefill_content
      ? this.getFormAnnexe7Detail(data, onChangeHandler)
      : null;
    return (
      <>
        <Grid item xs={12}>
          <CheckboxInput
            id="update_prefill_content"
            name="update_prefill_content"
            label="Modifier le contenu pré-rempli ?"
            value={data.update_prefill_content}
            onChange={(e) =>
              onChangeHandler("update_prefill_content", {
                target: { value: e.target.checked },
              })
            }
            margin="normal"
            fullWidth
          />
        </Grid>
        {formAnnexe7Detail}
      </>
    );
  }

  getFormAnnexe7Detail(data, onChangeHandler) {
    const { classes, affairesStore } = this.props;
    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            gutterBottom
            className={classes.sectionTitle}
          >
            Bloc 5
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="bloc_5_entreprise_b_uuid"
            label="Bloc 5 - Entreprise B"
            className={classes.selectContainer}
            value={data.bloc_5_entreprise_b_uuid}
            margin="normal"
            collectionStore={affairesStore}
            name="bloc_5_entreprise_b_uuid"
            onChangeHandler={(name, e) => {
              // pour garder les formulaires de l'affaire fonctionnel
              this.setSites(name, e, data);
              onChangeHandler(name, e);
            }}
            type="autocomplete"
            autocompleteProps={{
              collectionName: "entreprises",
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="bloc_5_etablissement_b_uuid"
            label="Bloc 5 - Etablissement B"
            className={classes.selectContainer}
            value={data.bloc_5_etablissement_b_uuid}
            disabled={!data.bloc_5_entreprise_b_uuid}
            margin="normal"
            collectionStore={affairesStore}
            name="bloc_5_etablissement_b_uuid"
            onChangeHandler={onChangeHandler}
            fullWidth
            select
          >
            {this.getEtablissements(data.bloc_5_entreprise_b_uuid)}
          </TextInput>
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="bloc_5_date_enlevement_b"
            label="Bloc 5 - Date d'enlèvement B"
            value={data.bloc_5_date_enlevement_b}
            margin="normal"
            collectionStore={affairesStore}
            name="bloc_5_date_enlevement_b"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="bloc_5_moyen_transport_b"
            label="Bloc 5 - Moyen de transport B"
            value={data.bloc_5_moyen_transport_b}
            margin="normal"
            collectionStore={affairesStore}
            name="bloc_5_moyen_transport_b"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="bloc_5_entreprise_c_uuid"
            label="Bloc 5 - Entreprise C"
            className={classes.selectContainer}
            value={data.bloc_5_entreprise_c_uuid}
            margin="normal"
            collectionStore={affairesStore}
            name="bloc_5_entreprise_c_uuid"
            onChangeHandler={(name, e) => {
              // pour garder les formulaires de l'affaire fonctionnel
              this.setSites(name, e, data);
              onChangeHandler(name, e);
            }}
            type="autocomplete"
            autocompleteProps={{
              collectionName: "entreprises",
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="bloc_5_etablissement_c_uuid"
            label="Bloc 5 - Etablissement C"
            className={classes.selectContainer}
            value={data.bloc_5_etablissement_c_uuid}
            disabled={!data.bloc_5_entreprise_c_uuid}
            margin="normal"
            collectionStore={affairesStore}
            name="bloc_5_etablissement_c_uuid"
            onChangeHandler={onChangeHandler}
            fullWidth
            select
          >
            {this.getEtablissements(data.bloc_5_entreprise_c_uuid)}
          </TextInput>
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="bloc_5_date_enlevement_c"
            label="Bloc 5 - Date d'enlèvement C"
            value={data.bloc_5_date_enlevement_c}
            margin="normal"
            collectionStore={affairesStore}
            name="bloc_5_date_enlevement_c"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="bloc_5_moyen_transport_c"
            label="Bloc 5 - Moyen de transport C"
            value={data.bloc_5_moyen_transport_c}
            margin="normal"
            collectionStore={affairesStore}
            name="bloc_5_moyen_transport_c"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            gutterBottom
            className={classes.sectionTitle}
          >
            Bloc 7
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="bloc_7_etablissement_uuid"
            label="Bloc 7 - Etablissement"
            className={classes.selectContainer}
            value={data.bloc_7_etablissement_uuid}
            margin="normal"
            collectionStore={affairesStore}
            name="bloc_7_etablissement_uuid"
            onChangeHandler={onChangeHandler}
            fullWidth
            select
          >
            {this.getEtablissements(this.state.affaire.client_uuid)}
          </TextInput>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            gutterBottom
            className={classes.sectionTitle}
          >
            Bloc 9
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.formTextArea}>
            <label for="bloc_9_text" className={classes.textarealabel}>
              Bloc 9 - Texte
            </label>
            <textarea
              id="bloc_9_text"
              className={classes.textarea}
              rows={4}
              onBlur={onChangeHandler.bind(this, "bloc_9_text")}
            >
              {data.bloc_9_text}
            </textarea>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            gutterBottom
            className={classes.sectionTitle}
          >
            Bloc 11
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <TextInput
            id="bloc_11_transit_1"
            label="Bloc 11 - Transit 1"
            value={data.bloc_11_transit_1}
            margin="normal"
            collectionStore={affairesStore}
            name="bloc_11_transit_1"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            id="bloc_11_transit_2"
            label="Bloc 11 - Transit 2"
            value={data.bloc_11_transit_2}
            margin="normal"
            collectionStore={affairesStore}
            name="bloc_11_transit_2"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            id="bloc_11_transit_3"
            label="Bloc 11 - Transit 3"
            value={data.bloc_11_transit_3}
            margin="normal"
            collectionStore={affairesStore}
            name="bloc_11_transit_3"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
      </Grid>
    );
  }

  setSites(name, e, data) {
    const entreprises_fields = ["fournisseur_uuid", "client_uuid"];
    data[name] = e.target.value;
    this.getSitesFromUuids(
      entreprises_fields
        .map((k) => this.state.affaire[k])
        .concat(
          ["bloc_5_entreprise_b_uuid", "bloc_5_entreprise_c_uuid"].map(
            (k) => data[k]
          )
        )
    );
  }

  /**
   * Retourne le markup pour la section Emails.
   *
   * Les Emails instanciés et/ou envoyés sont extraits de la liste associée à
   * l'Affaire.
   */
  getPartialEmails() {
    const { classes, user } = this.props;
    const { affaire, modified } = this.state;
    const hasEditRight = hasRights("admin-cud-affaires", user);
    let transportEmailKey = affaire.email_transport_options.email_key;
    let emails = affaire.emails_rendered;

    let required = {};
    // L'email Transport dépend du Transporteur et du Prix du transport à l'achat
    if (modified) {
      required[transportEmailKey] =
        "Les modifications de l'Affaire doivent d'abord être validées";
    } else if (!affaire.transporteur_uuid || !affaire.prix_transport_achat) {
      required[transportEmailKey] =
        "Les champs « Transporteur » et « Prix du transport à " +
        "l'achat » doivent être renseignés";
    } else if (
      emails[transportEmailKey]["state"] === "TODO" &&
      emails[transportEmailKey]["destinataires_uuid"] === null
    ) {
      required[transportEmailKey] =
        "Le Transporteur doit avoir au moins un Contact en charge " +
        "de la Logistique";
    } else {
      required[transportEmailKey] = "";
    }

    // L'email Enlèvement dépend de la Date d'enlèvement et du Poids total
    // d'achat, ainsi que des Poids et Prix d'achat des Articles
    // @todo Remplacer la condition de Poids total d'achat par une condition
    // @todo d'existence du Prix d'achat unitaire pour chacun des Articles
    if (modified) {
      required["enlevement"] =
        "Les modifications de l'Affaire doivent d'abord être validées";
    } else if (
      !affaire.date_enlevement ||
      !(affaire.poids_estime > 0) ||
      affaire.lots.length <= 0
    ) {
      required["enlevement"] =
        "Le champ « Date d'enlèvement » et les Articles " +
        "doivent être renseignés ainsi que le poids Estimé";
    } else if (
      emails["enlevement"]["state"] === "TODO" &&
      emails["enlevement"]["destinataires_uuid"] === null
    ) {
      required["enlevement"] =
        "Le Fournisseur doit avoir au moins un Contact en charge " +
        "de la Logistique";
    } else {
      required["enlevement"] = "";
    }

    // L'email Livraison dépend de la Date de livraison et du Poids total de
    // vente, ainsi que des Poids et Prix de vente des Articles
    // @todo Remplacer la condition de Poids total de vente par une
    // @todo condition d'existence du Prix de vente unitaire pour chacun des
    // @todo Articles
    if (modified) {
      required["livraison"] =
        "Les modifications de l'Affaire doivent d'abord être validées";
    } else if (
      !affaire.date_livraison ||
      !(affaire.poids_estime > 0) ||
      affaire.lots.length <= 0
    ) {
      required["livraison"] =
        "Le champ « Date de livraison » et les Articles " +
        "doivent être renseignés ainsi que le poids Estimé";
    } else if (
      emails["livraison"]["state"] === "TODO" &&
      emails["livraison"]["destinataires_uuid"] === null
    ) {
      required["livraison"] =
        "Le Client doit avoir au moins un Contact en charge " +
        "de la Logistique";
    } else {
      required["livraison"] = "";
    }

    // L'email Bordereau dépend de l'envoi de l'email Enlèvement, ainsi que
    // des Poids et Prix d'achat des Articles
    if (modified) {
      required["bordereau"] =
        "Les modifications de l'Affaire doivent d'abord être validées";
    } else if (!affaire.date_enlevement || !(affaire.poids_total_achat > 0)) {
      required["bordereau"] =
        "La date d'enlèvement doit être renseignée et les Articles " +
        "(poids et prix) doivent être renseignés";
    } else if (
      emails["bordereau"]["state"] === "TODO" &&
      emails["bordereau"]["destinataires_uuid"] === null
    ) {
      required["bordereau"] =
        "Le Fournisseur doit avoir au moins un Contact en charge " +
        "du Bordereau d'achat";
    } else {
      required["bordereau"] = "";
    }

    required["contrat"] = "";
    required["contrat_vente"] = "";

    required["annexe"] = "";
    required["demande_poids"] = "";
    required["liasse_documentaire_reception"] = "";
    required["liasse_documentaire_expedition"] = "";

    // L'email Demande de cotation peut être demandé n'importe quand car c'est un chiffrage ?
    if (modified) {
      required["demande_cotation_transport"] =
        "Les modifications de l'Affaire doivent d'abord être validées";
    } else {
      required["demande_cotation_transport"] = "";
    }
    required[""] = "";

    if (affaire.deleted_at) {
      return;
    } else {
      return (
        <>
          <Grid item xs={12} className={classes.section}>
            <Typography
              className={classes.sectionTitle}
              gutterBottom
              variant="button"
            >
              Emails
            </Typography>
            <Grid container style={{ marginTop: "1em" }}>
              <Grid item xs={2} />
              <Grid item xs={2}>
                <EmailButton
                  className={classes.emailButton}
                  email={emails["demande_cotation_transport"]}
                  key={this.getEmailKey("demande_cotation_transport")}
                  label={"Demande cotation transport"}
                  onSent={this.handleSent.bind(this)}
                  required={required["demande_cotation_transport"]}
                />
              </Grid>
              <Grid item xs={2}>
                <EmailButton
                  className={classes.emailButton}
                  email={emails[transportEmailKey]}
                  key={this.getEmailKey(transportEmailKey)}
                  showPopupInfo={affaire.email_transport_options.has_popup}
                  popupMessage={affaire.email_transport_options.popup_message}
                  label={"Ordre de tpt"}
                  onSent={this.handleSent.bind(this)}
                  required={required[transportEmailKey]}
                />
              </Grid>
              <Grid item xs={2}>
                <EmailButton
                  className={classes.emailButton}
                  email={emails["enlevement"]}
                  key={this.getEmailKey("enlevement")}
                  label={"Confirmer l'enlèvement"}
                  onSent={this.handleSent.bind(this)}
                  required={required["enlevement"]}
                  disabled={
                    !hasEditRight || this.state.disabled.bouton_enlevement
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <EmailButton
                  className={classes.emailButton}
                  email={emails["livraison"]}
                  key={this.getEmailKey("livraison")}
                  label={"Confirmer la livraison"}
                  onSent={this.handleSent.bind(this)}
                  required={required["livraison"]}
                  disabled={affaire.type_vente_depart}
                  asynchrone={true}
                  type="livraison"
                  showAdditionalParamsAsyncForm={true}
                  affaire_uuid={affaire.uuid}
                  formAdditionAsyncParams={this.getFormLivraison.bind(this)}
                />
              </Grid>
              <Grid item xs={2} />
              <Grid item xs={2} />
              <Grid item xs={2} style={{ marginTop: "1em" }}>
                <EmailButton
                  asynchrone={true}
                  type="contrat"
                  showAdditionalParamsAsyncForm={true}
                  formAdditionAsyncParams={this.getFormEmailContrat.bind(
                    this,
                    true
                  )}
                  affaire_uuid={affaire.uuid}
                  className={classes.emailButton}
                  email={emails["contrat"]}
                  key={this.getEmailKey("contrat")}
                  label={"contrat achat"}
                  onSent={this.handleSent.bind(this)}
                  required={required["contrat"]}
                  id="email-contrat"
                />
              </Grid>
              <Grid item xs={2} style={{ marginTop: "1em" }}>
                <EmailButton
                  asynchrone={true}
                  type="contrat_vente"
                  showAdditionalParamsAsyncForm={true}
                  formAdditionAsyncParams={this.getFormEmailContrat.bind(
                    this,
                    false
                  )}
                  affaire_uuid={affaire.uuid}
                  className={classes.emailButton}
                  email={emails["contrat_vente"]}
                  key={this.getEmailKey("contrat_vente")}
                  label={"contrat vente"}
                  onSent={this.handleSent.bind(this)}
                  required={required["contrat_vente"]}
                  id="email-contrat-vente"
                />
              </Grid>
              <Grid item xs={2} style={{ marginTop: "1em" }}>
                {this.getSendEmailAnnexeButton(emails, required, classes)}
              </Grid>
              <Grid item xs={2} style={{ marginTop: "1em" }}>
                {this.getSendEmailPoidsButton(emails, required, classes)}
              </Grid>
              <Grid item xs={2} />
              <Grid item xs={2} />
              <Grid item xs={2} style={{ marginTop: "1em" }}>
                <EmailButton
                  className={classes.emailButton}
                  email={emails["bordereau"]}
                  key={this.getEmailKey("bordereau")}
                  label={"Envoyer le bordereau"}
                  onSent={this.handleSent.bind(this)}
                  required={required["bordereau"]}
                  disabled={affaire.disabled_email_bordereau_achat}
                />
              </Grid>
              <Grid item xs={2} style={{ marginTop: "1em" }}>
                <EmailButton
                  className={classes.emailButton}
                  email={emails["liasse_documentaire_reception"]}
                  key={this.getEmailKey("liasse_documentaire_reception")}
                  label={"Liasse documentaire reception"}
                  onSent={this.handleSent.bind(this)}
                  required={required["liasse_documentaire_reception"]}
                />
              </Grid>
              <Grid item xs={2} style={{ marginTop: "1em" }}>
                <EmailButton
                  className={classes.emailButton}
                  email={emails["liasse_documentaire_expedition"]}
                  key={this.getEmailKey("liasse_documentaire_expedition")}
                  label={"Liasse documentaire expedition"}
                  onSent={this.handleSent.bind(this)}
                  required={required["liasse_documentaire_expedition"]}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      );
    }
  }

  getSendEmailPoidsButton(emails, required, classes) {
    if (emails["demande_poids"]["state"] !== "TODO") {
      return (
        <EmailButton
          className={classes.emailButton}
          email={emails["demande_poids"]}
          key={this.getEmailKey("demande_poids")}
          label={"Demande de poids"}
          onSent={this.handleSent.bind(this)}
          required={required["demande_poids"]}
        />
      );
    } else {
      return (
        <EmailButton
          className={classes.emailButton}
          email={emails["demande_poids"]}
          key={this.getEmailKey("demande_poids")}
          label={"Demande de poids"}
          onSent={this.handleSent.bind(this)}
          required={required["demande_poids"]}
        />
      );
    }
  }

  getSendEmailAnnexeButton(emails, required, classes) {
    if (emails["annexe"]["state"] !== "TODO") {
      return (
        <EmailButton
          className={classes.emailButton}
          email={emails["annexe"]}
          key={this.getEmailKey("annexe")}
          label={"Renvoyer l'Annexe VII"}
          onSent={this.handleSent.bind(this)}
          required={required["annexe"]}
          asynchrone={true}
          type="annexe"
          affaire_uuid={this.state.affaire.uuid}
          showAdditionalParamsAsyncForm={true}
          formAdditionAsyncParams={this.getFormAnnexe7.bind(this)}
        />
      );
    } else {
      return (
        <EmailButton
          className={classes.emailButton}
          email={emails["annexe"]}
          key={this.getEmailKey("annexe")}
          label={"Envoyer l'Annexe VII"}
          onSent={this.handleSent.bind(this)}
          required={required["annexe"]}
          asynchrone={true}
          type="annexe"
          affaire_uuid={this.state.affaire.uuid}
          showAdditionalParamsAsyncForm={true}
          formAdditionAsyncParams={this.getFormAnnexe7.bind(this)}
        />
      );
    }
  }

  /**
   * Retourne true si le Poids total d'achat n'est pas dans la tolérance par
   * rapport au Poids estimé.
   */
  warnPoidsAchat() {
    const tolerance = 10 / 100;
    const { poids_estime, poids_total_achat } = this.state.affaire;
    return (
      poids_total_achat < (1 - tolerance) * poids_estime ||
      poids_total_achat > (1 + tolerance) * poids_estime
    );
  }

  /**
   * Retourne true si le Poids total de vente n'est pas dans la tolérance par
   * rapport au Poids estimé.
   */
  warnPoidsVente() {
    const tolerance = 10 / 100;
    const { poids_estime, poids_total_vente } = this.state.affaire;
    return (
      poids_total_vente < (1 - tolerance) * poids_estime ||
      poids_total_vente > (1 + tolerance) * poids_estime
    );
  }

  /**
   * Retourne true si l'Écart de poids n'est pas dans la tolérance par rapport
   * au Poids total d'achat.
   */
  warnPoidsEcart() {
    const tolerance = 10 / 100;
    const { poids_total_achat, poids_total_vente } = this.state.affaire;
    return (
      poids_total_vente < (1 - tolerance) * poids_total_achat ||
      poids_total_vente > (1 + tolerance) * poids_total_achat
    );
  }

  /**
   * Retourne true si la Marge brute est négative.
   */
  warnMarge() {
    const { marge } = this.state.affaire;
    return marge < 0;
  }

  /**
   * Retourne le markup pour la section Totaux.
   *
   * Le Poids total d'achat, l'Écart de poids et la Marge brute font l'objet
   * d'un avertissement si leur valeur n'est pas conforme.
   */
  getPartialTotaux() {
    const { affairesStore, classes } = this.props;
    const { affaire } = this.state;
    const threeDigits = new Intl.NumberFormat("fr-FR", {
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    });
    const twoDigits = new Intl.NumberFormat("fr-FR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return (
      <>
        <Grid item xs={12} className={classes.section}>
          <Typography
            variant="button"
            gutterBottom
            className={classes.sectionTitle}
          >
            Totaux
          </Typography>
          <Grid container>
            <Grid item xs={4}>
              <TextInput
                className={
                  this.warnPoidsAchat()
                    ? classes.totauxDisabledWarning
                    : classes.totauxDisabled
                }
                id="poids_total_achat"
                label="Poids total d'achat (t)"
                disabled={true}
                value={threeDigits.format(affaire.poids_total_achat)}
                margin="normal"
                collectionStore={affairesStore}
                name="poids_total_achat"
                fullWidth
              />
            </Grid>

            <Grid item xs={4}>
              <TextInput
                className={
                  this.warnPoidsVente()
                    ? classes.totauxDisabledWarning
                    : classes.totauxDisabled
                }
                id="poids_total_vente"
                label="Poids total de vente (t)"
                disabled={true}
                value={twoDigits.format(affaire.poids_total_vente)}
                margin="normal"
                collectionStore={affairesStore}
                name="poids_total_vente"
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextInput
                className={
                  this.warnPoidsEcart()
                    ? classes.totauxDisabledWarning
                    : classes.totauxDisabled
                }
                id="poids_total_ecart"
                label="Écart de poids (t)"
                disabled={true}
                value={threeDigits.format(affaire.poids_total_ecart)}
                margin="normal"
                collectionStore={affairesStore}
                name="poids_total_ecart"
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextInput
                className={classes.totauxDisabled}
                id="ca_achat"
                label="Chiffre d'affaires d'achat (€)"
                disabled={true}
                value={threeDigits.format(affaire.ca_achat)}
                margin="normal"
                collectionStore={affairesStore}
                name="ca_achat"
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextInput
                className={classes.totauxDisabled}
                id="ca_vente"
                label="Chiffre d'affaires de vente (€)"
                disabled={true}
                value={twoDigits.format(affaire.ca_vente)}
                margin="normal"
                collectionStore={affairesStore}
                name="ca_vente"
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextInput
                className={
                  this.warnMarge()
                    ? classes.totauxDisabledWarning
                    : classes.totauxDisabled
                }
                id="marge"
                label="Marge brute transport inclus (€)"
                disabled={true}
                value={threeDigits.format(affaire.marge)}
                margin="normal"
                collectionStore={affairesStore}
                name="marge"
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }

  /**
   * Recharge le détail de l'Affaire après modification des Lots afin de
   * mettre à jour les Emails et les Totaux.
   */
  handleChangeLots() {
    const { dispatch } = this.props;
    const { affaire } = this.state;
    this.setState({ unmountBonLivraison: true });
    collectionActions(
      dispatch,
      "affaires",
      "SHOW",
      affaire,
      this.updateAffaire.bind(this)
    );
  }

  getInformationPesees() {
    const { affairesStore } = this.props;
    const { affaire } = this.state;

    let pesees = affaire.pesee_complementaire.map((pesee) => {
      return (
        <>
          <Grid item xs={2}>
            <TextInput
              id={pesee.uuid}
              label={"Réf pesée n°" + pesee.ordre}
              disabled={true}
              value={pesee.poid}
              margin="normal"
              collectionStore={affairesStore}
              name={"pesee n°" + pesee.ordre}
              fullWidth
            />
          </Grid>
        </>
      );
    });

    return (
      <>
        <Grid item xs={2}>
          <TextInput
            id="plaque_remorque"
            label="Plaque remorque"
            disabled={true}
            value={affaire.plaque_remorque}
            margin="normal"
            collectionStore={affairesStore}
            name="plaque_remorque"
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <TextInput
            id="num_carte"
            label="N° carte"
            disabled={true}
            value={affaire.num_carte}
            margin="normal"
            collectionStore={affairesStore}
            name="num_carte"
            fullWidth
          />
        </Grid>
        {pesees}
      </>
    );
  }

  /**
   * Retourne le markup pour la section Lots (dits 'Articles').
   *
   * La modification des Lots entraîne le rechargement du détail de l'Affaire
   * afin de mettre à jour les Emails et les Totaux.
   */
  getPartialLots() {
    const { classes } = this.props;
    const { affaire } = this.state;
    const is_from_trashed = affaire.deleted_at;

    return (
      <>
        <Grid item xs={12} className={classes.section}>
          <Grid container xs={12}>
            <Grid item xs={2} className={classes.pl}>
              <CheckboxInput
                id="pl_necessaire"
                label="PL nécéssaire"
                value={affaire.pl_necessaire}
                margin="normal"
                name="pl_necessaire"
                disabled={affaire.disable_pl_inputs || is_from_trashed}
                onChangeHandler={this.onChangeAffaireAndSubmitHandler.bind(
                  this
                )}
              />
            </Grid>
            <Grid item xs={2} className={classes.pl}>
              <CheckboxInput
                id="pl_manuel_prete"
                label="PL manuelle prête"
                value={affaire.pl_manuel_prete}
                margin="normal"
                name="pl_manuel_prete"
                disabled={affaire.disable_pl_inputs || is_from_trashed}
                onChangeHandler={this.onChangeAffaireAndSubmitHandler.bind(
                  this
                )}
              />
            </Grid>
            {this.getInformationPesees()}
            <Grid item xs={12}>
              <TextInput
                id="commentaire_reception"
                label="Commentaire Réception"
                key={"commentaire_reception"}
                value={affaire.commentaire_reception}
                margin="normal"
                collectionStore={this.props.affairesStore}
                disabled={is_from_trashed}
                name="commentaire_reception"
                onChangeHandler={(name, e) => {
                  this.setState({
                    affaire: {
                      ...affaire,
                      commentaire_reception: e.target.value,
                    },
                  });
                }}
                onBlur={() => {
                  this.onChangeAffaireAndSubmitHandler(
                    "commentaire_reception",
                    { target: { value: affaire.commentaire_reception } }
                  );
                }}
                fullWidth
              />
            </Grid>
          </Grid>
          <Lot
            actionsCallback={this.handleChangeLots.bind(this)}
            affaireUuid={affaire.uuid}
            datas={affaire.lots}
            pagination={false}
            search={true}
            clientCapeco={affaire.client ? affaire.client.is_capeco : false}
            disable_edit_add={is_from_trashed}
          />
        </Grid>
      </>
    );
  }

  /**
   * Retourne le markup pour la section Établissements (ou 'Sites').
   *
   * La liste des Établissements est filtrée pour n'afficher que les lieux
   * d'enlèvement et de livraison.
   */
  getPartialEtablissements() {
    const { classes } = this.props;
    const { affaire } = this.state;
    return (
      <>
        <Grid item xs={12} className={classes.section}>
          <Typography
            variant="button"
            gutterBottom
            className={classes.sectionTitle}
          >
            Sites
          </Typography>
          <Etablissement
            edit={false}
            deleteBtn={false}
            create={false}
            datas={[affaire.lieu_enlevement, affaire.lieu_livraison].filter(
              (e) => !!e
            )}
            pagination={false}
            search={false}
          />
        </Grid>
      </>
    );
  }

  getPartialDocuments() {
    const { classes } = this.props;
    const { affaire } = this.state;

    if (affaire.deleted_at) return null;

    return (
      <>
        <Grid item xs={12} className={classes.section}>
          <Typography
            variant="button"
            gutterBottom
            className={classes.sectionTitle}
          >
            Documents
          </Typography>
          <Grid item xs={12} style={{ marginTop: "1em" }}>
            <Button
              className={""}
              onClick={() => {
                let base_url_api = process.env.REACT_APP_API_URL
                  ? process.env.REACT_APP_API_URL
                  : "http://localhost:8000/api";
                window.open(
                  buildRoute(base_url_api + "/affaires/bordereau/:uuid", {
                    uuid: affaire.uuid,
                  }),
                  "_blank"
                );
              }}
              disabled={affaire.disabled_email_bordereau_achat}
              title={"Bordereau d'achat"}
              variant="outlined"
            >
              <FileCopy className={classes.leadIcon} /> Bordereau d'achat
            </Button>
            {!this.state.unmountBonLivraison ? (
              <BonLivraisonBtn affaire={affaire} />
            ) : null}
          </Grid>
        </Grid>
      </>
    );
  }

  getPartialDocumentFiles() {
    const { affaire } = this.state;
    if (!affaire || affaire.deleted_at) return null;

    return (
      <>
        <Documents
          key={this.state.fakeDocumentListrender}
          documentable_type="App\Models\Affaire"
          documentable_uuid={affaire.uuid}
        />
      </>
    );
  }

  getLogisticien() {
    const { affaire } = this.state;
    const { logisticiensStore, classes } = this.props;

    if (!affaire || !logisticiensStore.list) return null;

    const has_edit_right = hasRights(
      ["modifier-affectation-logistique"],
      this.props.user
    );

    const logisticien_uuid = affaire.logisticien_uuid
      ? affaire.logisticien_uuid
      : "none";
    const logisticiens = [
      {
        uuid: "none",
        code: "N/A",
      },
      ...logisticiensStore.list,
    ];
    const options = logisticiens.map((logisticien) => {
      return (
        <MenuItem value={logisticien.uuid} key={logisticien.uuid}>
          {logisticien.code}
        </MenuItem>
      );
    });

    return (
      <TextField
        disabled={!has_edit_right}
        label="Logisticien"
        value={logisticien_uuid}
        margin="normal"
        onChange={(e) => {
          const logisticien_uuid = e.target.value;
          updateAffaireLogisticien(this.props.dispatch, affaire.uuid, {
            logisticien_uuid:
              logisticien_uuid === "none" ? null : logisticien_uuid,
            view: "detail",
          });
        }}
        className={classes.selectContainer}
        select
      >
        {options}
      </TextField>
    );
  }

  getPartialFacturesAnnexe() {
    const { classes } = this.props;
    const { affaire } = this.state;

    if (affaire.deleted_at) return null;

    return (
      <>
        <Grid item xs={12} className={classes.section}>
          <Typography
            variant="button"
            gutterBottom
            className={classes.sectionTitle}
          >
            Factures Annexe
          </Typography>
          <Grid item xs={12} style={{ marginTop: "1em" }}>
            {this.state.hide_facture_annexe ? <CircularProgress /> : null}
            {!this.state.hide_facture_annexe ? (
              <FactureAnnexe
                update_facture_update={() => {
                  collectionActions(this.props.dispatch, "affaires", "SHOW", {
                    uuid: this.props.match.params.uuid,
                  });
                }}
                affaire={affaire}
                showActionsBtn={true}
                collectionCrud_options={{
                  persistDatatableOptions: {
                    id: "facture_annexe_affaire",
                    exipiration_minutes: 60 * 12, // 12 Heures
                  },
                }}
                facture_update={() => {
                  collectionActions(this.props.dispatch, "affaires", "SHOW", {
                    uuid: this.props.match.params.uuid,
                  });
                }}
              />
            ) : null}
          </Grid>
        </Grid>
      </>
    );
  }

  getPartialNonConformite() {
    const { classes } = this.props;
    const { affaire } = this.state;

    if (!affaire) return null;

    return (
      <>
        <Grid item xs={12} className={classes.section}>
          <Typography
            variant="button"
            gutterBottom
            className={classes.sectionTitle}
          >
            Non Conformités
          </Typography>
          <Grid item xs={12} style={{ marginTop: "1em" }}>
            <NonConformite
              affaire={{
                uuid: affaire.uuid,
                reference_interne: affaire.reference_interne,
                date_livraison: affaire.date_livraison,
                lots: affaire.lots,
              }}
              defaultClotureActive={true}
              createdDefaultValues={{
                created_at: new Date().toISOString().split("T")[0],
                numero_affaire: affaire.reference_interne,
                affaire_uuid: affaire.uuid,
                date_livraison: affaire.date_livraison,
                etat_label: "En Création",
                documents: [],
                selected_documents_uuids: [],
                is_closed: false,
              }}
            />
          </Grid>
        </Grid>
      </>
    );
  }

  getNotifications() {
    const { affaire } = this.state;
    if (!affaire || affaire.notifications.length === 0) return null;
    const notifications = [];

    const { classes } = this.props;

    affaire.notifications.forEach((notification, index) => {
      notifications.push(
        <div
          className={classes.notification + " " + classes[notification.type]}
          key={index}
        >
          {notification.message}
        </div>
      );
    });

    return (
      <div className={classes.notificationsContainer}>{notifications}</div>
    );
  }

  /**
   * Retourne le markup du composant.
   */
  render() {
    const { classes } = this.props;
    const { affaire } = this.state;
    if (!affaire) {
      return (
        <>
          <h4 className={classes.center}>
            Récupération des données actualisées auprès du serveur
          </h4>
          <CircularProgress />
        </>
      );
    }
    return (
      <>
        <Grid container className={classes.root}>
          {this.getNotifications()}
          {this.getPartialDetail()}
          <hr className={classes.hr} />
          {this.getPartialFacturesAnnexe()}
          <hr className={classes.hr} />
          {this.getPartialDocuments()}
          <hr className={classes.hr} />
          {this.getPartialEmails()}
          <hr className={classes.hr} />
          {this.getPartialNonConformite()}
          <hr className={classes.hr} />
          {this.getPartialTotaux()}
          <hr className={classes.hr} />
          {this.getPartialLots()}
          <hr className={classes.hr} />
          {this.getPartialDocumentFiles()}
          <hr className={classes.hr} />
          {this.getPartialEtablissements()}
        </Grid>
        <Modal
          openModal={this.state.openConfirmationCamionPasseModal}
          onCloseHandler={this.onCloseConfirmationCamionPasseModal.bind(this)}
          fullWidth={true}
          maxWidth={"sm"}
          children={this.getContentConfirmationCamionPasseModal()}
          disabledEnter={true}
          onSubmitHandler={this.onSubmitConfirmationCamionPasseModal.bind(this)}
        />
      </>
    );
  }
}

Detail = withStyles(AffaireCss)(Detail);

Detail = connect((store) => {
  return {
    affairesStore: store.collections.affaires,
    etatsStore: store.collections.etats,
    articlesStore: store.collections.articles,
    transportsStore: store.collections.transports,
    etablissementsStore: store.collections.etablissements,
    user: store.auth.user,
    logisticiensStore: store.collections.logisticiens,
    caseStockageStore: store.collections.caseStockage,
  };
})(Detail);

export default Detail;
