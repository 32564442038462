import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import {
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Tab,
  Tabs,
  Typography,
  TextField,
  Dialog,
  Button,
  DialogActions,
  RadioGroup,
  FormControlLabel,
  Radio,
  CircularProgress,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import {
  Check,
  EuroSymbol,
  FileCopy,
  MoreHoriz,
  SettingsBackupRestore,
  LocalShippingOutlined,
  LocalShipping,
  Clear,
  Edit,
} from "@material-ui/icons";

import { buildRoute } from "../../../router/Tools";
import {
  collectionActions,
  duplicateAffaire,
  restoreAffaire,
  loadCollectionAttribute,
  updateAffaireLogisticien,
} from "../../../reducers/collectionsReducer";
import CollectionCrud from "../../common/Components/CollectionCrud";
import Lot from "../Lot";
import TextInput from "../../common/Components/TextInput";
import Modal from "../../common/Components/Modal";

import AffaireCss from "./css/AffaireCss";
import NumberInput from "../../common/Components/NumberInput";
import { getFromLocalStorage, storeToLocalStorage } from "../../tools/Tools";
import { hasRights } from "../../common/Tools/Tools";
import EmailButton from "../Email";
import {
  addNotification,
  axiosErrorHandler,
} from "../../../reducers/notificationReducer";
import { themeComplement } from "../AppAdmin/css/theme";
import moment from "moment";
import CheckboxInput from "../../common/Components/CheckboxInput";
import DateInput from "../../common/Components/DateInput";

import { axiosClient } from "../../../axios";
import DocumentPicker from "../../common/Components/DocumentPicker";

const TABS = {
  COMMERCIAUX: "commerciaux",
  LOGISTIQUE: "logistique",
  SUPPRIMER: "supprimees",
};

const LOCAL_STORAGE_KEY = "AFFAIRE_INDEX";

function TabContainer(props) {
  return (
    <Typography component="div" style={{ width: "100%" }}>
      {props.children}
    </Typography>
  );
}

class Affaire extends Component {
  static defaultProps = {
    delete: true,
    pagination: true,
    search: true,
  };

  static propTypes = {
    delete: PropTypes.bool,
    entrepriseUuid: PropTypes.string,
    pagination: PropTypes.bool,
    search: PropTypes.bool,
  };

  state = {
    openLotModal: false,
    affaireUuid: null,
    affaire: null,
    updateCamionIsfetching: false,
    affaireCamionUuid: null,
    entreprise_uuids: [],
    openModalDuplicate: false,
    affaire_uuid_duplicate: null,
    duplicateField: {
      transporteur_uuid: true,
      prix_transport_achat_forfait: true,
      prix_transport_vente: true,
      commentaires: false,
      factures_annexes: false,
      prix: false,
    },
    affaire_restore: null,
    openModalRestore: false,
    current_filter: null,
    etat_filter: ["all"],
    type_filter: ["all"],
    commercial_achat_filter: this.props.commercial_achat_filter
      ? this.props.commercial_achat_filter
      : ["all"],
    commercial_vente_filter: ["all"],
    camion_passe_filter: ["all"],
    logisticien_filter: ["all"],
    date_filter: ["all"],
    type_meg_filter: ["all"],
    type_date_filter: "date_livraison",
    date_filter_date_debut: "",
    date_filter_date_fin: "",
    openModalDatePicker: false,
    type_taff: false,
    list_campagnes_taf: [],
    selectedTab: this.getInitialTab(),
    disabledTabs: this.props.hasOwnProperty("disabledTabs")
      ? this.props.disabledTabs
      : false,
    lockedDates: {},
    lieu_livraison_uuid: null,
    lieu_enlevement_uuid: null,
    local_storage_key: LOCAL_STORAGE_KEY,
  };

  getInitialTab() {
    if (this.props.location.pathname === "/affaires/logistique") {
      return TABS.LOGISTIQUE;
    }
    if (this.props.location.pathname === "/affaires/supprimees") {
      return TABS.SUPPRIMER;
    }
    return TABS.COMMERCIAUX;
  }

  componentWillMount() {
    let { dispatch } = this.props;
    collectionActions(dispatch, "affaires", "RESET_LIST");
  }

  componentDidMount() {
    const {
      dispatch,
      facturation_cells,
      history,
      commercial_achat_filter,
      local_storage_key,
    } = this.props;
    if (local_storage_key) {
      this.setState({ local_storage_key: local_storage_key });
    }
    let filter_localStorage = getFromLocalStorage(
      local_storage_key ? local_storage_key : LOCAL_STORAGE_KEY
    );
    if (filter_localStorage) {
      if (commercial_achat_filter) {
        filter_localStorage.commercial_achat_filter = commercial_achat_filter;
      }
      this.setState(filter_localStorage, () => {
        this.loadAsyncData(this.state.current_filter, true);
      });
    }

    collectionActions(dispatch, "users", "INDEX", {
      params: {
        commerciaux: true,
      },
    });
    loadCollectionAttribute(dispatch, "list", "etats", this.props.etatsStore);
    loadCollectionAttribute(
      dispatch,
      "list",
      "logisticiens",
      this.props.logisticiensStore
    );
    loadCollectionAttribute(
      dispatch,
      "list",
      "transports",
      this.props.transportsStore
    );
    loadCollectionAttribute(
      this.props.dispatch,
      "list",
      "type_affaires",
      this.props.typeAffairesStore
    );
    if (
      !this.state.selectedTab &&
      !facturation_cells &&
      !this.state.disabledTabs
    ) {
      this.setState({ selectedTab: TABS.COMMERCIAUX });
      history.push({
        pathname: `/affaires/${TABS.COMMERCIAUX}`,
        state: { selectedTab: TABS.COMMERCIAUX },
      });
    }
    this.initializeDuplicateField();
  }

  initializeDuplicateField() {
    this.setState({
      duplicateField: {
        transporteur_uuid: true,
        prix_transport_achat_forfait: true,
        prix_transport_vente: true,
        factures_annexes: false,
        prix: false,
        suivi_logistique: false,
        commentaires: false,
        commentaire_production: false,
        facturation_commentaire: false,
      },
    });
  }

  getLockedDateLivraison(lieu_uuid) {
    this.setState({ lieu_livraison_uuid: lieu_uuid });
    axiosClient
      .get(`flux/getLockedDates`, {
        params: {
          site_enlevement_uuid: this.state.lieu_enlevement_uuid,
          site_livraison_uuid: lieu_uuid,
        },
      })
      .then((response) => {
        this.setState({ lockedDates: response.data });
      });
  }

  getLockedDateEnlevement(lieu_uuid) {
    this.setState({ lieu_enlevement_uuid: lieu_uuid });
    axiosClient
      .get(`flux/getLockedDates`, {
        params: {
          site_enlevement_uuid: lieu_uuid,
          site_livraison_uuid: this.state.lieu_livraison_uuid,
        },
      })
      .then((response) => {
        this.setState({ lockedDates: response.data });
      });
  }

  getFilterValue(value) {
    return value.length === 1 && value[0] === "all" ? null : value;
  }

  loadAsyncData(filters) {
    let { dispatch, data_params, affairesStore, entreprise_uuid } = this.props;

    if (affairesStore.fetching) return;

    const {
      type_filter,
      etat_filter,
      commercial_achat_filter,
      commercial_vente_filter,
      logisticien_filter,
      type_meg_filter,
      date_filter,
      type_date_filter,
      camion_passe_filter,
      selectedTab,
    } = this.state;

    if (!data_params) data_params = {};
    data_params.params = {
      ...data_params.params,
      type_affaire_slug: this.getFilterValue(type_filter),
      etat_filter: this.getFilterValue(etat_filter),
      commercial_achat_filter: this.getFilterValue(commercial_achat_filter),
      commercial_vente_filter: this.getFilterValue(commercial_vente_filter),
      logisticien_filter: this.getFilterValue(logisticien_filter),
      type_meg_filter: this.getFilterValue(type_meg_filter),
      date_filter: this.getFilterValue(date_filter),
      type_date_filter: type_date_filter,
      camion_passe_filter: this.getFilterValue(camion_passe_filter),
      ...filters,
      with_demande_poids_emails:
        this.state.selectedTab === TABS.LOGISTIQUE ? 1 : 0,
    };

    if (entreprise_uuid) {
      data_params.params.entreprise_uuid = entreprise_uuid;
    }
    if (selectedTab !== "logistique") {
      data_params.params.logisticien_filter = null;
    }

    if (selectedTab === "supprimees") {
      data_params.params.only_trash = true;
    }

    // Gardes fou pour éviter que des requêtes partent sans pagination
    if (!data_params.params.oniti_pagination) {
      data_params.params.oniti_pagination = 1;
    }
    if (!data_params.params.oniti_perpage) {
      data_params.params.oniti_perpage = 15;
    }

    return collectionActions(
      dispatch,
      "affaires",
      "INDEX",
      data_params,
      null,
      true
    );
  }

  getDeleteModalTitle() {
    return (
      <div>
        <EuroSymbol />
        <span>{"Suppression de l'affaire"}</span>
      </div>
    );
  }

  getDeleteModalContent() {
    return (
      <Typography>
        {"Êtes-vous sûr de vouloir supprimer cette affaire ?"}
      </Typography>
    );
  }

  getCreateUpdateModalTitle(affaire) {
    let title = !!affaire
      ? "Modification de l'affaire : " + affaire.reference_interne
      : "Ajout d'une nouvelle affaire";
    return (
      <div>
        <EuroSymbol />
        <span>{title}</span>
      </div>
    );
  }

  getEtats() {
    if (!this.props.etatsStore.list) {
      return [];
    }
    return this.props.etatsStore.list.map((etat) => {
      return (
        <MenuItem value={etat.uuid} key={etat.uuid}>
          {etat.nom}
        </MenuItem>
      );
    });
  }

  getTransports() {
    if (!this.props.transportsStore.list) {
      return [];
    }
    return this.props.transportsStore.list.map((transport) => {
      return (
        <MenuItem value={transport.uuid} key={transport.uuid}>
          {transport.nom}
        </MenuItem>
      );
    });
  }

  getCampagnesTaf() {
    if (this.state.list_campagnes_taf.length === 0) {
      return [
        <MenuItem value={null} key={null}>
          Aucune Campagne Compatible avec l'affaire
        </MenuItem>,
      ];
    }
    return this.state.list_campagnes_taf.map((campagne) => {
      return (
        <MenuItem value={campagne.uuid} key={campagne.uuid}>
          {campagne.nom}
        </MenuItem>
      );
    });
  }

  /**
   * Permet de charger les sites suite au changement dans le formulaire d'autocomplete
   * @param  {[type]} entreprise_uuids [description]
   * @return {[type]}                  [description]
   */
  getSitesFromUuids(entreprise_uuids) {
    collectionActions(this.props.dispatch, "etablissements", "INDEX", {
      params: { entreprise_uuids },
    });
  }

  getEtablissements(entreprise_uuid) {
    if (!this.props.etablissementsStore.list) {
      return [];
    }
    let list = this.props.etablissementsStore.list.slice(); // Pprofshallow copy
    if (entreprise_uuid) {
      list = list.filter((e) => e.entreprise_uuid === entreprise_uuid);
    }
    return list.map((e) => {
      return (
        <MenuItem value={e.uuid} key={e.uuid}>
          {e.nom}
          {!!e.ville ? " (" + e.ville.toUpperCase() + ")" : ""}
        </MenuItem>
      );
    });
  }

  updateCampagneTafList() {
    setTimeout(() => {
      collectionActions(
        this.props.dispatch,
        "campagne-tafs",
        "INDEX_WITHOUT_DISPATCH",
        {
          params: {
            etablissements_uuid: [
              this.state.lieu_enlevement_uuid,
              this.state.lieu_livraison_uuid,
            ],
          },
        }
      ).then((response) => {
        this.setState({
          list_campagnes_taf: response.data,
        });
      });
    }, 100);
  }

  getCreateUpdateModalContent(affaire, affairesStore, onChangeHandler) {
    const { user } = this.props;
    let {
      fournisseur_uuid,
      client_uuid,
      lieu_enlevement_uuid,
      lieu_livraison_uuid,
      poids_estime,
      transport_uuid,
      commentaires,
      facturation_commentaire,
      commentaire_production,
      suivi_logistique,
      campagne_taf_uuid,
    } = affaire;

    if (
      (!!client_uuid || !!fournisseur_uuid) &&
      JSON.stringify(this.state.entreprise_uuids) !==
        JSON.stringify([client_uuid, fournisseur_uuid])
    ) {
      /* eslint-disable-next-line */
      this.state.entreprise_uuids = [client_uuid, fournisseur_uuid];
      this.getSitesFromUuids(this.state.entreprise_uuids);
    }
    const { classes } = this.props;

    return (
      <Grid container>
        <Grid item xs={2}>
          <CheckboxInput
            id="type_vente_depart"
            label="Vente en départ"
            value={affaire.type_vente_depart}
            margin="normal"
            name="type_vente_depart"
            onChangeHandler={onChangeHandler}
          />
        </Grid>
        <Grid item xs={2}>
          <CheckboxInput
            id="type_achat_rendu"
            label="Achat en rendu"
            value={affaire.type_achat_rendu}
            margin="normal"
            name="type_achat_rendu"
            onChangeHandler={onChangeHandler}
          />
        </Grid>
        <Grid item xs={2}>
          <CheckboxInput
            id="type_pour_compte"
            label="Affaire pour compte"
            value={affaire.type_pour_compte}
            margin="normal"
            name="type_pour_compte"
            onChangeHandler={onChangeHandler}
          />
        </Grid>
        <Grid item xs={2}>
          <CheckboxInput
            id="type_taf"
            label="Affaire TAF"
            value={affaire.type_taf}
            margin="normal"
            name="type_taf"
            onChangeHandler={(name, e) => {
              onChangeHandler(name, e);
              setTimeout(() => {
                onChangeHandler("campagne_taf_uuid", {
                  target: { value: null },
                });
              }, 100);
              this.setState({ type_taf: e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <CheckboxInput
            id="is_mouvement_interne"
            label="Mouvement Interne"
            value={affaire.is_mouvement_interne}
            margin="normal"
            name="is_mouvement_interne"
            onChangeHandler={onChangeHandler}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="fournisseur_uuid"
            label="Fournisseur"
            value={fournisseur_uuid}
            required={true}
            margin="normal"
            collectionStore={affairesStore}
            name="fournisseur_uuid"
            onChangeHandler={onChangeHandler}
            className={classes.selectContainer}
            type="autocomplete"
            autocompleteProps={{
              collectionName: "entreprises",
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="client_uuid"
            label="Client"
            value={client_uuid}
            required={true}
            margin="normal"
            collectionStore={affairesStore}
            name="client_uuid"
            onChangeHandler={onChangeHandler}
            className={classes.selectContainer}
            type="autocomplete"
            autocompleteProps={{
              collectionName: "entreprises",
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="lieu_enlevement_uuid"
            label="Site d'enlèvement"
            disabled={!fournisseur_uuid}
            value={lieu_enlevement_uuid}
            required={true}
            margin="normal"
            collectionStore={affairesStore}
            name="lieu_enlevement_uuid"
            onChangeHandler={(name, e) => {
              onChangeHandler(name, e);
              this.getLockedDateEnlevement(e.target.value);
              this.updateCampagneTafList();
            }}
            className={classes.selectContainer}
            fullWidth
            select
          >
            {this.getEtablissements(fournisseur_uuid)}
          </TextInput>
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="lieu_livraison_uuid"
            label="Site de livraison"
            disabled={!client_uuid}
            value={lieu_livraison_uuid}
            required={true}
            margin="normal"
            collectionStore={affairesStore}
            name="lieu_livraison_uuid"
            onChangeHandler={(name, e) => {
              onChangeHandler(name, e);
              this.getLockedDateLivraison(e.target.value);
              this.updateCampagneTafList();
            }}
            className={classes.selectContainer}
            fullWidth
            select
          >
            {this.getEtablissements(client_uuid)}
          </TextInput>
        </Grid>
        <Grid item xs={6}>
          <NumberInput
            id="poids_estime"
            label="Poids total estimé (t)"
            value={poids_estime}
            required={true}
            margin="normal"
            collectionStore={affairesStore}
            name="poids_estime"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="transport_uuid"
            label="Transport"
            value={transport_uuid}
            required={true}
            margin="normal"
            collectionStore={affairesStore}
            name="transport_uuid"
            onChangeHandler={onChangeHandler}
            className={classes.selectContainer}
            fullWidth
            select
          >
            {this.getTransports()}
          </TextInput>
        </Grid>

        <Grid item xs={6}>
          <DateInput
            id="date_enlevement"
            label="Date d'enlèvement"
            value={affaire.date_enlevement}
            margin="normal"
            collectionStore={affairesStore}
            name="date_enlevement"
            onChangeHandler={onChangeHandler}
            fullWidth
            disabled={!lieu_enlevement_uuid || !lieu_livraison_uuid}
            pickertype="datetime"
            shouldDisableDate={(date) => {
              const date_formated = moment(date).format("YYYY-MM-DD");
              const locked_dates = this.state.lockedDates.enlevement
                ? this.state.lockedDates.enlevement
                : [];
              return (
                !hasRights("logistique-bypass-blocage-planning", user) &&
                locked_dates.includes(date_formated)
              );
            }}
            renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) => {
              const locked_dates = this.state.lockedDates.enlevement
                ? this.state.lockedDates.enlevement
                : [];
              const isLocked = locked_dates.includes(
                moment(day).format("YYYY-MM-DD")
              );
              const className = isLocked ? classes.dayLocked : "";
              const title = isLocked ? "Date enlevement bloquée" : "";
              return (
                <div className={className} title={title}>
                  {dayComponent}
                </div>
              );
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <DateInput
            id="date_livraison"
            label="Date de livraison"
            value={affaire.date_livraison}
            margin="normal"
            collectionStore={affairesStore}
            name="date_livraison"
            onChangeHandler={onChangeHandler}
            fullWidth
            disabled={!lieu_livraison_uuid || !lieu_enlevement_uuid}
            pickertype="datetime"
            shouldDisableDate={(date) => {
              const date_formated = moment(date).format("YYYY-MM-DD");
              const locked_dates = this.state.lockedDates.livraison
                ? this.state.lockedDates.livraison
                : [];
              return (
                !hasRights("logistique-bypass-blocage-planning", user) &&
                locked_dates.includes(date_formated)
              );
            }}
            renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) => {
              const locked_dates = this.state.lockedDates.livraison
                ? this.state.lockedDates.livraison
                : [];
              const isLocked = locked_dates.includes(
                moment(day).format("YYYY-MM-DD")
              );
              const className = isLocked ? classes.dayLocked : "";
              const title = isLocked ? "Date de livraison bloquée" : "";
              return (
                <div className={className} title={title}>
                  {dayComponent}
                </div>
              );
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="campagne_taf_uuid"
            label="Campagne TAF"
            value={campagne_taf_uuid}
            required={true}
            margin="normal"
            collectionStore={affairesStore}
            name="campagne_taf_uuid"
            disabled={!affaire.type_taf}
            onChangeHandler={(name, e) => {
              if (!affaire.type_taf) {
                e.target.value = null;
              }
              onChangeHandler(name, e);
            }}
            className={classes.selectContainer}
            fullWidth
            select
          >
            {this.getCampagnesTaf()}
          </TextInput>
        </Grid>
        <Grid item xs={4}>
          <CheckboxInput
            id="pl_necessaire"
            label="PL nécéssaire"
            value={affaire.pl_necessaire}
            margin="normal"
            name="pl_necessaire"
            disabled={affaire.disable_pl_inputs}
            onChangeHandler={onChangeHandler}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="suivi_logistique"
            label="Commentaire Logistique"
            value={suivi_logistique}
            margin="normal"
            collectionStore={affairesStore}
            name="suivi_logistique"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="commentaires"
            label="Commentaire Commercial"
            value={commentaires}
            margin="normal"
            collectionStore={affairesStore}
            name="commentaires"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="commentaire_production"
            label="Commentaire Production"
            value={commentaire_production}
            margin="normal"
            collectionStore={affairesStore}
            name="commentaire_production"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="facturation_commentaire"
            label="Commentaire Facturation"
            value={facturation_commentaire}
            margin="normal"
            collectionStore={affairesStore}
            name="facturation_commentaire"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
      </Grid>
    );
  }

  OnchangeDuplicateField(name, event) {
    const { duplicateField } = this.state;
    this.setState({
      duplicateField: {
        ...duplicateField,
        [name]: event.target.value,
      },
    });
  }

  getModalContentDuplicate() {
    const { classes } = this.props;
    if (!this.state.affaire_duplicate) return null;
    let alertMsg = null;
    if (this.state.affaire_duplicate.type_taf) {
      alertMsg = (
        <Typography className={classes.alert}>
          "Duplication d'une affaire de TAF : bien vérifier la campagne !"
        </Typography>
      );
    }

    const { duplicateField } = this.state;

    const fields = [
      { label: "transporteur", value: "transporteur_uuid" },
      {
        label: "prix transport achat forfait",
        value: "prix_transport_achat_forfait",
      },
      { label: "prix transport vente", value: "prix_transport_vente" },
      { label: "commentaires logistique", value: "suivi_logistique" },
      { label: "commentaires commercial", value: "commentaires" },
      { label: "commentaires production", value: "commentaire_production" },
      { label: "commentaires facturation", value: "facturation_commentaire" },
      { label: "factures annexes", value: "factures_annexes" },
      { label: "prix", value: "prix" },
    ];

    const displayChecbox = fields.map((field) => {
      return (
        <CheckboxInput
          id={field.value}
          label={field.label}
          value={duplicateField[field.value]}
          margin="normal"
          name={field.value}
          onChangeHandler={this.OnchangeDuplicateField.bind(this)}
        />
      );
    });

    return (
      <>
        <DialogTitle key="title" id="alert-dialog-slide-title">
          <FileCopy className={classes.ico} /> Confirmation Duplication
        </DialogTitle>
        <DialogContent key="content">
          {alertMsg}
          <Typography>
            Confirmez vous la duplication de l'affaire avec les champs suivants
            {" " + this.state.affaire_duplicate.reference_interne} ?
          </Typography>
          {displayChecbox}
        </DialogContent>
      </>
    );
  }

  getModalContentRestore() {
    const { classes } = this.props;
    if (!this.state.affaire_restore) return null;
    let alertMsg = null;
    return (
      <>
        <DialogTitle key="title" id="alert-dialog-slide-title">
          <SettingsBackupRestore className={classes.ico} /> Confirmation De la
          restoration
        </DialogTitle>
        <DialogContent key="content-restore">
          {alertMsg}
          <Typography>
            Confirmez vous la restoration de l'affaire{" "}
            {this.state.affaire_restore.reference_interne} ?
          </Typography>
        </DialogContent>
      </>
    );
  }

  onSubmitHandlerModalDuplicate() {
    const DuplicateFieldValue = Object.keys(this.state.duplicateField).filter(
      (key) => this.state.duplicateField[key]
    );

    duplicateAffaire(
      this.props.dispatch,
      this.state.affaire_duplicate.uuid,
      DuplicateFieldValue,
      () => {
        this.loadAsyncData(this.state.current_filter, true);
      }
    );
    this.onCloseHandlerModalDuplicate();
  }

  onCloseHandlerModalDuplicate() {
    this.setState({ openModalDuplicate: false, affaire_duplicate: null });
    this.initializeDuplicateField();
  }

  onCloseHandlerModalRestore() {
    this.setState({ openModalRestore: false, affaire_restore: null });
  }

  onSubmitHandlerModalrestore() {
    restoreAffaire(this.props.dispatch, this.state.affaire_restore.uuid, () => {
      this.loadAsyncData(this.state.current_filter, true);
    });
    this.onCloseHandlerModalRestore();
  }

  getDuplicateButton(affaire) {
    if (this.props.hasOwnProperty("duplicate") && !this.props.duplicate)
      return null;
    if (this.state.selectedTab === "supprimees") {
      return null;
    }
    const { classes } = this.props;
    return (
      <IconButton
        key="duplicate"
        aria-label="Dupliquer"
        onClick={() => {
          this.setState({
            openModalDuplicate: true,
            affaire_duplicate: affaire,
          });
        }}
        title="Dupliquer"
        className={classes.button}
      >
        <FileCopy />
      </IconButton>
    );
  }

  getRestoreButton(affaire) {
    if (this.state.selectedTab !== "supprimees") {
      return null;
    }
    const { classes } = this.props;
    return (
      <IconButton
        key="restore"
        aria-label="Restorer"
        onClick={() => {
          this.setState({
            openModalRestore: true,
            affaire_restore: affaire,
          });
        }}
        title="Restorer"
        className={classes.button}
      >
        <SettingsBackupRestore />
      </IconButton>
    );
  }

  // Bouton historique avec conservation de l'url
  // getDetailButton(affaire) {
  //   const { classes } = this.props;
  //   return (
  //     <IconButton
  //       key="detail"
  //       aria-label="Afficher"
  //       color="primary"
  //       onClick={() => {
  //         this.props.history.push(
  //           buildRoute("/affaires/:uuid", { uuid: affaire.uuid })
  //         );
  //       }}
  //       title="Afficher"
  //       className={classes.button}
  //     >
  //       <MoreHoriz />
  //     </IconButton>
  //   );
  // }

  getDetailButton(affaire) {
    const { classes } = this.props;
    return (
      <a
        href={buildRoute("/affaires/:uuid", { uuid: affaire.uuid })}
        className={classes.button}
        // target="_blank"
        // rel="noopener noreferrer"
      >
        <MoreHoriz />
      </a>
    );
  }

  onChangeHandlerFilters(name, e) {
    let values = e.target.value;
    /**
     * Gestion de la value all
     * si on clique dessus on vide la liste et ne garde que all
     * si on clique sur un autre element on enleve all
     * si on vide la liste on rajoute all
     */
    if (values.length > 0) {
      if (this.state[name].indexOf("all") !== -1) {
        values = values.filter((value) => value !== "all");
      } else if (values.indexOf("all") !== -1) {
        values = ["all"];
      }
    } else {
      values = ["all"];
    }

    this.setState(
      {
        [name]: values,
      },
      () => {
        storeToLocalStorage(
          this.state.local_storage_key,
          {
            etat_filter: this.state.etat_filter,
            type_filter: this.state.type_filter,
            commercial_achat_filter: this.state.commercial_achat_filter,
            commercial_vente_filter: this.state.commercial_vente_filter,
            date_filter: this.state.date_filter,
            type_date_filter: this.state.type_date_filter,
            logisticien_filter: this.state.logisticien_filter,
          },
          60 * 12
        );
        // eslint-disable-next-line
        this.state.current_filter.page = 1; // On force la page 1
        this.loadAsyncData(this.state.current_filter, true);
      }
    );
  }

  getCommercialAchatFilter() {
    const { commercial_achat_filter } = this.state;
    return this.generateCommercialSelect(
      commercial_achat_filter,
      "commercial_achat_filter",
      "Commercial Achat"
    );
  }
  getCommercialVenteFilter() {
    const { commercial_vente_filter } = this.state;
    return this.generateCommercialSelect(
      commercial_vente_filter,
      "commercial_vente_filter",
      "Commercial Vente"
    );
  }

  getCamionPasseFilter() {
    const { camion_passe_filter } = this.state;
    return this.generateCamionPasseSelect(
      camion_passe_filter,
      "camion_passe_filter",
      "Camion passe"
    );
  }

  generateCamionPasseSelect(value, name, label) {
    const { classes } = this.props;
    const { camion_passe_filter } = this.state;

    const camionPasse = [
      {
        value: "all",
        nom: "Tous",
      },
      {
        value: "true",
        nom: "Oui",
      },
      {
        value: "false",
        nom: "Non",
      },
    ];
    const options = camionPasse.map((etat) => {
      return (
        <MenuItem value={etat.value} key={etat.value}>
          {etat.nom}
        </MenuItem>
      );
    });

    return (
      <TextField
        id="camion_passe_filter_affaire"
        label="Camion passe"
        margin="normal"
        onChange={this.onChangeHandlerFilters.bind(this, "camion_passe_filter")}
        className={classes.selectContainer}
        SelectProps={{
          multiple: true,
          value: camion_passe_filter,
        }}
        select
      >
        {options}
      </TextField>
    );
  }

  generateCommercialSelect(value, name, label) {
    const { usersStore, classes, disabledCommercialFilter } = this.props;
    if (!usersStore.list || disabledCommercialFilter) return null;
    const commerciaux = [
      {
        uuid: "all",
        firstname: "Tous",
      },
      ...usersStore.list,
    ];
    const options = commerciaux.map((user) => {
      return (
        <MenuItem value={user.uuid} key={user.uuid}>
          {user.firstname}
        </MenuItem>
      );
    });

    return (
      <TextField
        id={name}
        label={label}
        margin="normal"
        onChange={this.onChangeHandlerFilters.bind(this, name)}
        className={classes.selectContainer}
        SelectProps={{
          multiple: true,
          value: value,
        }}
        select
      >
        {options}
      </TextField>
    );
  }

  getEtatsFilter() {
    const { etatsStore, classes, disabledEtatFilter } = this.props;
    const { etat_filter } = this.state;
    if (!etatsStore.list || disabledEtatFilter) return null;
    const etats = [
      {
        uuid: "all",
        nom: "Tous",
      },
      ...etatsStore.list,
    ];
    const options = etats.map((etat) => {
      return (
        <MenuItem value={etat.uuid} key={etat.uuid}>
          {etat.nom}
        </MenuItem>
      );
    });

    return (
      <TextField
        id="etat_filter_affaire"
        label="État"
        margin="normal"
        onChange={this.onChangeHandlerFilters.bind(this, "etat_filter")}
        className={classes.selectContainer}
        SelectProps={{
          multiple: true,
          value: etat_filter,
        }}
        select
      >
        {options}
      </TextField>
    );
  }

  getLogisticiensFilter() {
    const { logisticiensStore, classes, disabledEtatFilter } = this.props;
    const { logisticien_filter, selectedTab } = this.state;
    if (
      !logisticiensStore.list ||
      disabledEtatFilter ||
      selectedTab !== "logistique"
    )
      return null;
    const logisticiens = [
      {
        uuid: "all",
        full_name: "Tous",
      },
      ...logisticiensStore.list,
    ];
    const options = logisticiens.map((logisticien) => {
      return (
        <MenuItem value={logisticien.uuid} key={logisticien.uuid}>
          {logisticien.full_name}
        </MenuItem>
      );
    });

    return (
      <TextField
        id="logisticien_filter_affaire"
        label="Logisticien"
        margin="normal"
        onChange={this.onChangeHandlerFilters.bind(this, "logisticien_filter")}
        className={classes.selectContainer}
        select
        SelectProps={{
          multiple: true,
          value: logisticien_filter,
        }}
      >
        {options}
      </TextField>
    );
  }

  getTypeMegFilter() {
    const { classes, enableMegFilter } = this.props;
    const { type_meg_filter } = this.state;
    if (!enableMegFilter) return null;

    const TypeMeg = [
      {
        value: "all",
        full_name: "Tous",
      },
      {
        value: "brouillon",
        full_name: "Brouillon",
      },
    ];
    const options = TypeMeg.map((type) => {
      return (
        <MenuItem value={type.value} key={type.value}>
          {type.full_name}
        </MenuItem>
      );
    });

    return (
      <TextField
        id="type_meg_filter"
        label="Type MEG"
        margin="normal"
        onChange={this.onChangeHandlerFilters.bind(this, "type_meg_filter")}
        className={classes.selectContainer}
        select
        SelectProps={{
          multiple: true,
          value: type_meg_filter,
        }}
      >
        {options}
      </TextField>
    );
  }

  getTypeAffaireFilter() {
    const { disabledTypeAffaireFilter } = this.props;
    const { typeAffairesStore, classes } = this.props;
    const { type_filter } = this.state;
    if (!typeAffairesStore.list || disabledTypeAffaireFilter) return null;

    const typesAffaires = [
      {
        slug: "all",
        libelle: "Tous",
      },
      ...typeAffairesStore.list,
    ];
    const options = typesAffaires.map((typeAffaire) => {
      return (
        <MenuItem value={typeAffaire.slug} key={typeAffaire.slug}>
          {typeAffaire.libelle}
        </MenuItem>
      );
    });

    return (
      <TextField
        id="type_affaire_filter"
        label="Type Affaire"
        margin="normal"
        onChange={this.onChangeHandlerFilters.bind(this, "type_filter")}
        className={classes.selectContainer}
        SelectProps={{
          multiple: true,
          value: type_filter,
        }}
        select
      >
        {options}
      </TextField>
    );
  }

  onClickDatePeriode(nb_months) {
    var date_debut = moment().subtract(nb_months, "months");
    if (nb_months === 0 || nb_months === 1) {
      date_debut = date_debut.startOf("month");
    }
    date_debut = date_debut.format("YYYY-MM-DD");

    var date_fin = moment();
    if (nb_months === 1 || nb_months === 0) {
      date_fin = date_fin.subtract(nb_months, "months").endOf("month");
    }
    date_fin = date_fin.format("YYYY-MM-DD");

    this.setState({
      date_filter_date_debut: date_debut,
      date_filter_date_fin: date_fin,
    });
  }

  getDateFilter() {
    const { classes } = this.props;
    const {
      date_filter,
      openModalDatePicker,
      date_filter_date_debut,
      date_filter_date_fin,
    } = this.state;

    const date_filter_new =
      date_filter_date_debut === "" || date_filter_date_fin === ""
        ? ["all"]
        : [date_filter_date_debut, date_filter_date_fin];
    const dates_values = date_filter.filter((date) => date !== "all");
    return (
      <>
        <TextField
          id="date_filter"
          label="Date"
          margin="normal"
          onClick={() => this.setState({ openModalDatePicker: true })}
          className={classes.selectContainer}
          value={
            dates_values.length > 0
              ? dates_values
                  .map((date) => moment(date).format("DD/MM/YYYY"))
                  .join(" au ")
              : "Toutes"
          }
        />
        <Dialog
          open={openModalDatePicker}
          onClose={() => {
            this.setState({ openModalDatePicker: false });
          }}
        >
          <DialogTitle key="title" id="alert-dialog-slide-title">
            <Typography>Sélectionner les Dates</Typography>
          </DialogTitle>
          <DialogContent>
            <Button
              color="primary"
              onClick={this.onClickDatePeriode.bind(this, 0)}
            >
              M
            </Button>
            <Button
              color="primary"
              onClick={this.onClickDatePeriode.bind(this, 1)}
            >
              M-1
            </Button>
            <Button
              color="primary"
              onClick={this.onClickDatePeriode.bind(this, 3)}
            >
              3 mois
            </Button>
            <Button
              color="primary"
              onClick={this.onClickDatePeriode.bind(this, 6)}
            >
              6 mois
            </Button>
            <Button
              color="primary"
              onClick={this.onClickDatePeriode.bind(this, 12)}
            >
              12 mois
            </Button>
            <Button
              color="primary"
              onClick={() => {
                this.setState({
                  date_filter_date_debut: "",
                  date_filter_date_fin: "",
                });
              }}
            >
              Toutes
            </Button>
            <TextField
              label="Date début"
              margin="normal"
              className={classes.dateInput}
              type="date"
              value={date_filter_date_debut}
              onChange={(e) => {
                this.setState({ date_filter_date_debut: e.target.value });
              }}
            />

            <TextField
              label="Date fin"
              margin="normal"
              className={classes.dateInput}
              type="date"
              value={date_filter_date_fin}
              onChange={(e) => {
                this.setState({ date_filter_date_fin: e.target.value });
              }}
            />
            <RadioGroup
              defaultValue="date_livraison"
              name="radio-buttons-group"
              className={classes.radioGroup}
              value={this.state.type_date_filter}
              onChange={(e) => {
                this.setState({ type_date_filter: e.target.value });
              }}
            >
              <FormControlLabel
                value="date_livraison"
                control={<Radio />}
                label="Date Livraison"
              />
              <FormControlLabel
                value="date_enlevement"
                control={<Radio />}
                label="Date Enlèvement"
              />
            </RadioGroup>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.setState({ openModalDatePicker: false })}
            >
              Annuler
            </Button>
            <Button
              onClick={() =>
                this.setState(
                  { openModalDatePicker: false, date_filter: date_filter_new },
                  () => {
                    this.onChangeHandlerFilters("date_filter", {
                      target: { value: date_filter_new },
                    });
                  }
                )
              }
              color="primary"
            >
              Valider
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  getFilters(modal) {
    const { classes } = this.props;
    const typeAffaireFilter = this.getTypeAffaireFilter();
    const etatFilter = this.getEtatsFilter();
    const commercialAchatFilter = this.getCommercialAchatFilter();
    const commercialVenteFilter = this.getCommercialVenteFilter();
    const camionPasseFilter = this.getCamionPasseFilter();
    const logisticienFilter = this.getLogisticiensFilter();
    const dateFilter = this.getDateFilter();
    const typeMegFilter = this.getTypeMegFilter();

    if (
      (!typeAffaireFilter &&
        !etatFilter &&
        !commercialAchatFilter &&
        !commercialVenteFilter) ||
      (modal && modal.only)
    ) {
      return null;
    }
    const camionPasse_grid = camionPasseFilter ? (
      <Grid item xs={2}>
        {camionPasseFilter}
      </Grid>
    ) : null;
    const logisticien_grid = logisticienFilter ? (
      <Grid item xs={2}>
        {logisticienFilter}
      </Grid>
    ) : null;
    const date_grid = dateFilter ? (
      <Grid item xs={2}>
        {dateFilter}
      </Grid>
    ) : null;
    const typeMeg_grid = typeMegFilter ? (
      <Grid item xs={2}>
        {typeMegFilter}
      </Grid>
    ) : null;

    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography
            key="typo"
            variant="button"
            gutterBottom
            className={classes.crudTitle}
          >
            Filtres
          </Typography>
        </Grid>
        <Grid item xs={2}>
          {typeAffaireFilter}
        </Grid>
        <Grid item xs={2}>
          {etatFilter}
        </Grid>
        {typeMeg_grid}
        <Grid item xs={2}>
          {commercialAchatFilter}
        </Grid>
        <Grid item xs={2}>
          {commercialVenteFilter}
        </Grid>
        {camionPasse_grid}
        {logisticien_grid}
        {date_grid}
      </Grid>
    );
  }

  getTitleAndFilters() {
    const { classes } = this.props;

    return [
      <Grid>
        <Typography
          key="typo"
          variant="button"
          gutterBottom
          className={classes.crudTitle}
        >
          Affaires
        </Typography>
      </Grid>,
    ];
  }

  getDate(string) {
    if (!string) {
      return "";
    }
    let date = new Date(string);
    let options = {
      day: "numeric",
      month: "long",
      year: "numeric",
    };

    return date.toLocaleDateString("fr-FR", options);
  }

  onChangeFacturationHandler(affaire_uuid, name, event) {
    this.props.onChangeHandlerFacturation(affaire_uuid, name, event);
  }

  getCellsConfigCommerciaux() {
    if (this.props.facturation_cells) {
      return this.props.facturation_cells;
    } else {
      const { classes } = this.props;
      return [
        {
          datakey: "date_enlevement",
          title: "Date d'enlèvement",
          sortable: true,
          searchable: true,
          isDate: true,
          format: (obj, key) => {
            return this.getDate(obj[key]);
          },
        },
        {
          datakey: "reference_interne",
          title: "Référence",
          sortable: true,
          searchable: true,
        },
        {
          datakey: "reference_client",
          title: "Référence client",
          sortable: true,
          searchable: true,
        },
        {
          datakey: "reference_fournisseur",
          title: "Référence fournisseur",
          sortable: true,
          searchable: true,
        },
        {
          datakey: "articles_nom",
          title: "Articles",
          sortable: true,
          searchable: true,
          format: (obj, key) => obj.liste_lots,
        },
        {
          datakey: "familles_nom",
          title: "Familles",
          sortable: true,
          searchable: true,
          format: (obj, key) => obj.liste_familles,
        },
        {
          datakey: "fournisseur_nom",
          title: "Fournisseur",
          sortable: true,
          searchable: true,
        },
        {
          datakey: "client_nom",
          title: "Client",
          sortable: true,
          searchable: true,
        },

        {
          datakey: "transporteur_nom",
          title: "Transporteur",
          sortable: true,
          searchable: true,
        },
        {
          datakey: "etat_nom",
          title: "État",
          sortable: true,
          searchable: true,
        },
        {
          datakey: "has_proforma",
          title: "Proforma",
          sortable: true,
          searchable: true,
          format: (obj, key) => {
            if (obj[key]) {
              return (
                <span title="Au moins un proformat ouvert">
                  <EuroSymbol
                    style={{ color: obj.icon_color_has_proforma ?? "" }}
                  />
                </span>
              );
            } else {
              return null;
            }
          },
        },
        {
          datakey: "campagne_short",
          title: "Campagne",
          sortable: true,
          searchable: true,
          format: (obj, key) => {
            if (!obj[key]) return null;
            const campagne = obj[key];
            const id = campagne.numero + "-" + Math.random() * 1000;
            const info = (
              <div
                id={id}
                style={{ display: "none" }}
                className={classes.campagne_info}
              >
                <div>{campagne.nom}</div>
                <div>Entrant: {campagne.tonnage_entrant} T</div>
                <div>Sortant: {campagne.tonnage_sortant} T</div>
                <div>Perte: {campagne.tonnage_perte} T</div>
                <div>Solde: {campagne.solde_tonnage} T</div>
              </div>
            );
            return (
              <>
                <span
                  className={classes.campagneTitle}
                  onMouseEnter={() => {
                    document.getElementById(id).style.display = "block";
                  }}
                  onMouseLeave={() => {
                    document.getElementById(id).style.display = "none";
                  }}
                >
                  {campagne.numero}
                </span>
                {info}
              </>
            );
          },
        },
        {
          datakey: "type_transport",
          title: "Incoterm",
          sortable: false,
          searchable: false,
          format: (obj, key) => {
            return (
              <>
                <p className={classes.type_transport}>
                  {obj["type_transport"]}
                </p>
              </>
            );
          },
        },
        {
          datakey: "mail_contrat_achat_send",
          title: "CA",
          sortable: false,
          searchable: false,
          format: (obj, key) =>
            obj[key] ? (
              <Check
                title="Mail contrat achat Envoyé"
                style={{ color: "green" }}
              />
            ) : (
              <Clear title="Mail non envoyé" style={{ color: "red" }} />
            ),
        },
        {
          datakey: "mail_contrat_vente_send",
          title: "CV",
          sortable: false,
          searchable: false,
          format: (obj, key) =>
            obj[key] ? (
              <Check
                title="Mail contrat vente Envoyé"
                style={{ color: "green" }}
              />
            ) : (
              <Clear title="Mail non envoyé" style={{ color: "red" }} />
            ),
        },
        {
          datakey: "is_affaire_commerciale",
          title: "C",
          sortable: false,
          searchable: false,
          format: (obj, key) => {
            const is_affaire_commerciale = obj.is_affaire_commerciale;
            const is_mouvement_interne = obj.is_mouvement_interne;

            return (
              (is_affaire_commerciale ? "C" : "") +
              (is_affaire_commerciale && is_mouvement_interne ? "-" : "") +
              (is_mouvement_interne ? "MI" : "")
            );
          },
        },
        {
          datakey: "fake",
          title: "Docs",
          sortable: false,
          searchable: false,
          format: (obj, key) => {
            return this.getBtnDocuments(obj);
          },
        },
      ];
    }
  }

  getCellsConfigSupprime() {
    if (this.props.facturation_cells) {
      return this.props.facturation_cells;
    } else {
      const { classes } = this.props;
      return [
        {
          datakey: "date_enlevement",
          title: "Date d'enlèvement",
          sortable: true,
          searchable: true,
          isDate: true,
          format: (obj, key) => {
            return this.getDate(obj[key]);
          },
        },
        {
          datakey: "reference_interne",
          title: "Référence",
          sortable: true,
          searchable: true,
        },
        {
          datakey: "reference_client",
          title: "Référence client",
          sortable: true,
          searchable: true,
        },
        {
          datakey: "reference_fournisseur",
          title: "Référence fournisseur",
          sortable: true,
          searchable: true,
        },
        {
          datakey: "articles_nom",
          title: "Articles",
          sortable: true,
          searchable: true,
          format: (obj, key) => obj.liste_lots,
        },
        {
          datakey: "familles_nom",
          title: "Familles",
          sortable: true,
          searchable: true,
          format: (obj, key) => obj.liste_familles,
        },
        {
          datakey: "fournisseur_nom",
          title: "Fournisseur",
          sortable: true,
          searchable: true,
        },
        {
          datakey: "client_nom",
          title: "Client",
          sortable: true,
          searchable: true,
        },

        {
          datakey: "transporteur_nom",
          title: "Transporteur",
          sortable: true,
          searchable: true,
        },
        {
          datakey: "etat_nom",
          title: "État",
          sortable: true,
          searchable: true,
        },
        {
          datakey: "has_proforma",
          title: "Proforma",
          sortable: true,
          searchable: true,
          format: (obj, key) => {
            if (obj[key]) {
              return (
                <span title="Au moins un proformat ouvert">
                  <EuroSymbol
                    style={{ color: obj.icon_color_has_proforma ?? "" }}
                  />
                </span>
              );
            } else {
              return null;
            }
          },
        },
        {
          datakey: "campagne_short",
          title: "Campagne",
          sortable: true,
          searchable: true,
          format: (obj, key) => {
            if (!obj[key]) return null;
            const campagne = obj[key];
            const id = campagne.numero + "-" + Math.random() * 1000;
            const info = (
              <div
                id={id}
                style={{ display: "none" }}
                className={classes.campagne_info}
              >
                <div>{campagne.nom}</div>
                <div>Entrant: {campagne.tonnage_entrant} T</div>
                <div>Sortant: {campagne.tonnage_sortant} T</div>
                <div>Perte: {campagne.tonnage_perte} T</div>
                <div>Solde: {campagne.solde_tonnage} T</div>
              </div>
            );
            return (
              <>
                <span
                  className={classes.campagneTitle}
                  onMouseEnter={() => {
                    document.getElementById(id).style.display = "block";
                  }}
                  onMouseLeave={() => {
                    document.getElementById(id).style.display = "none";
                  }}
                >
                  {campagne.numero}
                </span>
                {info}
              </>
            );
          },
        },
        {
          datakey: "type_transport",
          title: "Incoterm",
          sortable: false,
          searchable: false,
          format: (obj, key) => {
            return (
              <>
                <p className={classes.type_transport}>
                  {obj["type_transport"]}
                </p>
              </>
            );
          },
        },
        {
          datakey: "deleted_at",
          title: "Suppression le",
          sortable: true,
          searchable: true,
          format: (obj, key) => {
            return this.getDate(obj[key]);
          },
        },
        {
          datakey: "deleted_by_full_name",
          title: "Suppression par",
          sortable: true,
          searchable: true,
        },
      ];
    }
  }

  getLogisticien(affaire) {
    const has_edit_right = hasRights(
      ["modifier-affectation-logistique"],
      this.props.user
    );
    const { logisticiensStore, classes } = this.props;

    const logisticien_uuid = affaire.logisticien_uuid
      ? affaire.logisticien_uuid
      : "none";
    const logisticiens = [
      {
        uuid: "none",
        code: "N/A",
      },
      ...logisticiensStore.list,
    ];
    const options = logisticiens.map((logisticien) => {
      return (
        <MenuItem value={logisticien.uuid} key={logisticien.uuid}>
          {logisticien.code}
        </MenuItem>
      );
    });

    return (
      <TextField
        disabled={!has_edit_right}
        label=""
        value={logisticien_uuid}
        margin="normal"
        onChange={(e) => {
          const logisticien_uuid = e.target.value;
          updateAffaireLogisticien(this.props.dispatch, affaire.uuid, {
            logisticien_uuid:
              logisticien_uuid === "none" ? null : logisticien_uuid,
          });
        }}
        className={classes.selectContainer}
        select
      >
        {options}
      </TextField>
    );
  }

  getBtnDocuments(row) {
    const { classes } = this.props;
    return (
      <DocumentPicker
        documentable_uuid={row.uuid}
        documentable_type="App\Models\Affaire"
        className={classes.documentButton}
        hasDocument={row.has_document}
        iconsize="small"
      />
    );
  }

  getCellsConfigLogistique() {
    const { classes } = this.props;

    if (this.props.facturation_cells) {
      return this.props.facturation_cells;
    } else {
      return [
        {
          datakey: "logisticien_uuid",
          title: "Logisticien",
          sortable: true,
          searchable: true,
          format: (obj, key) => {
            return this.getLogisticien(obj);
          },
        },
        {
          datakey: "date_enlevement",
          title: "Date d'enlèvement",
          sortable: true,
          searchable: true,
          isDate: true,
          format: (obj, key) => {
            return this.getDate(obj[key]);
          },
        },
        {
          datakey: "date_livraison",
          title: "Date de livraison",
          sortable: true,
          searchable: true,
          isDate: true,
          format: (obj, key) => {
            return this.getDate(obj[key]);
          },
        },
        {
          datakey: "reference_interne",
          title: "Référence",
          sortable: true,
          searchable: true,
          format: (obj, key) => {
            const ref_client = obj.reference_client;
            const ref_four = obj.reference_fournisseur;
            const complement_ref =
              (ref_client ? `${ref_client}` : "") +
              (ref_client && ref_four ? " / " : "") +
              (ref_four ? `${ref_four}` : "");
            return (
              <>
                <div>{obj.reference_interne}</div>
                <div className={classes.complement_ref}>{complement_ref}</div>
              </>
            );
          },
        },
        {
          datakey: "articles_nom",
          title: "Articles",
          sortable: true,
          searchable: true,
          format: (obj, key) => obj.liste_lots,
          className: classes.customCell,
        },
        {
          datakey: "conditionnements_nom",
          title: "Conditionnements",
          sortable: true,
          searchable: true,
          className: classes.customCell,
          format: (obj, key) => obj.liste_conditionnements,
        },
        {
          datakey: "fournisseur_nom",
          title: "Fournisseur",
          sortable: true,
          searchable: true,
          className: classes.customCell,
          format: (obj, key) =>
            `${obj.fournisseur_nom}\n(${obj.lieu_enlevement_nom})`,
        },
        {
          datakey: "lieu_enlevement_code_postal",
          title: "Enlèvement",
          sortable: true,
          searchable: true,
          className: classes.customCell,
          format: (obj, key) =>
            `${obj.lieu_enlevement_code_postal} ${obj.lieu_enlevement_ville}`,
        },
        {
          datakey: "client_nom",
          title: "Client",
          sortable: true,
          searchable: true,
          className: classes.customCell,
          format: (obj, key) =>
            `${obj.client_nom}\n(${obj.lieu_livraison_nom})`,
        },
        {
          datakey: "lieu_livraison_code_postal",
          title: "Livraison",
          sortable: true,
          searchable: true,
          className: classes.customCell,
          format: (obj, key) =>
            `${obj.lieu_livraison_code_postal} ${obj.lieu_livraison_ville}`,
        },
        {
          datakey: "transporteur_nom",
          title: "Transporteur",
          sortable: true,
          className: classes.customCell,
          searchable: true,
        },
        {
          datakey: "etat_nom",
          title: "État",
          sortable: true,
          searchable: true,
        },
        {
          datakey: "has_proforma",
          title: "Proforma",
          sortable: true,
          searchable: true,
          format: (obj, key) => {
            if (obj[key]) {
              return (
                <span title="Au moins un proformat ouvert">
                  <EuroSymbol
                    style={{ color: obj.icon_color_has_proforma ?? "" }}
                  />
                </span>
              );
            } else {
              return null;
            }
          },
        },
        {
          datakey: "suivi_logistique",
          title: "Suivi logistique",
          sortable: true,
          searchable: true,
        },
        {
          datakey: "type_transport",
          title: "Incoterm",
          sortable: false,
          searchable: false,
          format: (obj, key) => {
            return (
              <>
                <p className={classes.type_transport}>
                  {obj["type_transport"]}
                </p>
              </>
            );
          },
        },
        {
          datakey: "mail_contrat_achat_send",
          title: "CA",
          sortable: false,
          searchable: false,
          format: (obj, key) =>
            obj[key] ? (
              <Check
                title="Mail contrat achat Envoyé"
                style={{ color: "green" }}
              />
            ) : (
              <Clear title="Mail non envoyé" style={{ color: "red" }} />
            ),
        },
        {
          datakey: "mail_contrat_vente_send",
          title: "CV",
          sortable: false,
          searchable: false,
          format: (obj, key) =>
            obj[key] ? (
              <Check
                title="Mail contrat vente Envoyé"
                style={{ color: "green" }}
              />
            ) : (
              <Clear title="Mail non envoyé" style={{ color: "red" }} />
            ),
        },
        {
          datakey: "mail_enlevement_send",
          title: "E",
          sortable: false,
          searchable: false,
          format: (obj, key) =>
            obj[key] ? <Check title="Mail enlevement Envoyé" /> : null,
        },
        {
          datakey: "mail_livraison_send",
          title: "L",
          sortable: false,
          searchable: false,
          format: (obj, key) =>
            obj[key] ? <Check title="Mail livraison Envoyé" /> : null,
        },
        {
          datakey: "is_affaire_commerciale",
          title: "C",
          sortable: false,
          searchable: false,
          format: (obj, key) => {
            const is_affaire_commerciale = obj.is_affaire_commerciale;
            const is_mouvement_interne = obj.is_mouvement_interne;

            return (
              (is_affaire_commerciale ? "C" : "") +
              (is_affaire_commerciale && is_mouvement_interne ? "-" : "") +
              (is_mouvement_interne ? "MI" : "")
            );
          },
        },
        {
          datakey: "email_suivi_poids",
          title: "Email",
          format: (affaire) => this.getEmailBtn(affaire),
        },
      ];
    }
  }

  getIcone(affaire) {
    const { classes } = this.props;
    const { updateCamionIsfetching, affaireCamionUuid } = this.state;
    return updateCamionIsfetching && affaireCamionUuid == affaire.uuid ? (
      <CircularProgress className={classes.loader} size={28} />
    ) : (
      <LocalShipping style={{ color: affaire.camion_passe ? "green" : "" }} />
    );
  }

  getCamionBtn(affaire) {
    const { classes } = this.props;
    const { updateCamionIsfetching, affaireCamionUuid } = this.state;

    if (updateCamionIsfetching && affaireCamionUuid == affaire.uuid) {
      return <CircularProgress className={classes.loader} size={28} />;
    } else
      return affaire.camion_passe ? (
        <Button
          title={"Camion passé"}
          label={updateCamionIsfetching}
          className={classes.hoverIcon}
          onClick={() =>
            this.setState(
              {
                updateCamionIsfetching: true,
                affaireCamionUuid: affaire.uuid,
              },
              () => {
                this.handleUpdateCamionPasse(affaire);
              }
            )
          }
        >
          {this.getIcone(affaire)}
        </Button>
      ) : (
        <Button
          title={"Camion passé"}
          className={classes.hoverIcon}
          onClick={() =>
            this.setState(
              {
                updateCamionIsfetching: true,
                affaireCamionUuid: affaire.uuid,
              },
              () => {
                this.handleUpdateCamionPasse(affaire);
              }
            )
          }
        >
          <LocalShippingOutlined
            style={{ color: affaire.camion_passe ? "green" : "" }}
          />
        </Button>
      );
  }

  getEmailBtn(affaire) {
    if (!affaire.emails_status) return null;

    const { classes } = this.props;

    return (
      <EmailButton
        className={
          classes.btn_mail_poids +
          (affaire.alert_email_poids ? " " + classes.alert : "")
        }
        email={affaire.emails_status["demande_poids"]}
        type="demande_poids"
        affaire_uuid={affaire.uuid}
        key={affaire.uuid + "_email_poids"}
        required={""}
        label={"Poids"}
        onSent={(email) => {
          if (!email) {
            addNotification(this.props.dispatch, {
              message: "Impossible d’envoyer cet email.",
              bgColor: themeComplement.palette.notifications.error.color,
            });
            return;
          }
        }}
      />
    );
  }

  /**
   * Mise à jour de l'etat camion passe.
   *
   * @param {Affaire} affaire - L'objet Affaire de la ligne.
   */
  handleUpdateCamionPasse(affaire) {
    axiosClient
      .put("affaires/mise-a-jour-camion-passe/" + affaire.uuid, {
        camion_passe: !affaire.camion_passe,
        affaire_uuid: affaire.uuid,
      })
      .then((res) => {
        this.loadAsyncData(this.state.current_filter, true);
        this.setState({
          updateCamionIsfetching: false,
          affaireCamionUuid: null,
        });
      })
      .catch((error) => {
        axiosErrorHandler(this.props.dispatch, error);
        this.setState({
          updateCamionIsfetching: false,
          affaireCamionUuid: null,
        });
      });
  }

  /**
   * Traite les actions remontées par la modale de création d'Affaire.
   *
   * @param {string}  action  - L'identifiant de l'action.
   * @param {Affaire} affaire - L'objet Affaire retourné par l'action.
   */
  handleAffaireModalActions(action, affaire) {
    const { overrideAffaireActionCallback } = this.props;
    if (overrideAffaireActionCallback) {
      overrideAffaireActionCallback(action, affaire);
    } else {
      if (action === "create") {
        this.setState({
          openLotModal: true,
          affaireUuid: affaire.uuid,
          affaire: affaire,
        });
      }
      if (this.props.actionsCallback) this.props.actionsCallback();
    }
  }

  /**
   * Traite les actions remontées par la modale de création de Lot.
   *
   * À la création d'un Lot, l'action 'close' est générée avant
   * l'action 'create'.
   *
   * @param {string} action - L'identifiant de l'action.
   * @param {Lot}    lot    - L'objet Lot retourné par l'action.
   */
  handleLotModalActions(action, lot, clicknext) {
    if (action === "close") {
      this.setState({ openLotModal: false });
    }
    if (action === "create") {
      this.setState({ openLotModal: false }, () => {
        collectionActions(
          this.props.dispatch,
          "lots",
          "RESET_LIST",
          null,
          () => {
            // Rouvre la modale Lot pour permettre la création du Lot suivant
            this.setState({ openLotModal: clicknext });
            // Recharge la liste des Affaires pour mettre à jour
            // la colonne Articles
            this.loadAsyncData(this.state.current_filter, true);

            if (!clicknext) {
              this.props.history.push(
                buildRoute("/affaires/:uuid", { uuid: this.state.affaireUuid })
              );
            }
          }
        );
      });
    }
  }
  getrowClassName(affaire) {
    const { classes } = this.props;
    if (affaire.camion_calle_status === "alert-danger")
      return classes.rowBgDanger;
    if (affaire.camion_calle_status === "alert-warning")
      return classes.rowBgWarning;

    return null;
  }

  getInfoText(modal) {
    const { infoText } = this.props;
    if (!infoText || (modal && modal.only)) return null;
    return <p>{infoText}</p>;
  }

  listAffaires(cellsConfig) {
    const {
      datas,
      data_params,
      collectionCrud_options,
      modalOnly,
      modalOpen,
      createUpdateModalSubmit,
      createdDefaultValues,
      cancelUpdateCheck,
    } = this.props;
    const {
      affaireUuid,
      openLotModal,
      openModalDuplicate,
      openModalRestore,
      affaire,
    } = this.state;
    // Désactivé car ne fonctionne pas avec le mode asynchrone
    const persistDatatableOptions = !!collectionCrud_options
      ? collectionCrud_options.persistDatatableOptions
      : {
          id: "affaire_tab",
          exipiration_minutes: 60 * 12, // 12 Heures
        };
    const modal = modalOnly ? { only: true, open: modalOpen } : null;
    // const persistDatatableOptions = null;
    return (
      <>
        {this.getInfoText(modal)}
        {this.getFilters(modal)}
        <CollectionCrud
          ref={this.refCollectionCrud}
          asynchrone={datas ? false : true}
          asynchroneCallbackLoadData={
            datas ? null : this.loadAsyncData.bind(this)
          }
          collectionName={"affaires"}
          showBtnEdit={false}
          showBtnAdd={this.props.hasOwnProperty("add") ? this.props.add : true}
          rights={{
            create: "admin-cud-affaires",
            edit: "admin-cud-affaires",
            delete: "admin-d-affaires",
          }}
          persistDatatableOptions={persistDatatableOptions}
          datas={datas}
          loadDatas={
            !(!!datas || !!data_params || !!this.props.match.params.affaires)
          }
          modalMaxWidth={"lg"}
          modal={modal}
          showBtnDelete={
            this.state.selectedTab === "supprimees" ? false : this.props.delete
          }
          deleteModalTitle={this.getDeleteModalTitle}
          deleteModalContent={this.getDeleteModalContent}
          createUpdateModalTitle={this.getCreateUpdateModalTitle}
          createUpdateModalContent={this.getCreateUpdateModalContent.bind(this)}
          createUpdateModalSubmit={
            createUpdateModalSubmit ? createUpdateModalSubmit : "Suivant"
          }
          actionsCallback={this.handleAffaireModalActions.bind(this)}
          additionnalControllers={[
            this.getDuplicateButton.bind(this),
            this.getRestoreButton.bind(this),
            this.getDetailButton.bind(this),
            // this.getNewTabDetailButton.bind(this),
          ]}
          datatableConfig={{
            showPagination: this.props.pagination,
            showSearch: this.props.search,
            defaultSort: this.props.defaut_orderBy
              ? this.props.defaut_orderBy
              : "reference_interne",
            sortType: this.props.defaut_orderDirection
              ? this.props.defaut_orderDirection
              : "desc",
            nodatalabel: "Aucune affaire",
            rowsPerPageOptions: [50, 100],
            rowClassName: this.getrowClassName.bind(this),
          }}
          dataTableExtraNodes={[
            {
              element: this.getTitleAndFilters(),
              position: "top-left",
            },
          ]}
          cellsConfig={cellsConfig()}
          crudClass={this.props.crudClass ? this.props.crudClass : ""}
          getAsyncFilter={(filter) =>
            // eslint-disable-next-line
            (this.state.current_filter = filter)
          }
          defaultValuesCallback={() => {
            return createdDefaultValues || {};
          }}
          cancelUpdateCheck={cancelUpdateCheck}
        />
        <Lot
          actionsCallback={this.handleLotModalActions.bind(this)}
          affaireUuid={affaireUuid}
          localAffaire={affaire}
          createUpdateModalNext={"Suivant"}
          modal={{ only: true, open: openLotModal }}
        />
        <Modal
          openModal={openModalDuplicate}
          onCloseHandler={this.onCloseHandlerModalDuplicate.bind(this)}
          onSubmitHandler={this.onSubmitHandlerModalDuplicate.bind(this)}
          fullWidth={true}
          maxWidth={"sm"}
          children={this.getModalContentDuplicate()}
        />
        <Modal
          openModal={openModalRestore}
          onCloseHandler={this.onCloseHandlerModalRestore.bind(this)}
          onSubmitHandler={this.onSubmitHandlerModalrestore.bind(this)}
          fullWidth={true}
          maxWidth={"sm"}
          children={this.getModalContentRestore()}
        />
      </>
    );
  }

  getAffairesList(selectedTab) {
    const config = {
      [TABS.COMMERCIAUX]: this.getCellsConfigCommerciaux.bind(this),
      [TABS.LOGISTIQUE]: this.getCellsConfigLogistique.bind(this),
      [TABS.SUPPRIMER]: this.getCellsConfigSupprime.bind(this),
    };
    return (
      // L'attribut key est indispensable pour que la table soit rafraîchie au
      // changement d'onglet !
      <TabContainer key={selectedTab}>
        {this.listAffaires(config[selectedTab])}
      </TabContainer>
    );
  }

  getAffairesTabs() {
    if (this.state.disabledTabs) return null;
    const has_right_affaire_delete = hasRights(
      "admin-cud-affaires-supprimees",
      this.props.user
    );
    return [
      <Tab label={TABS.COMMERCIAUX} value={TABS.COMMERCIAUX} />,
      <Tab label={TABS.LOGISTIQUE} value={TABS.LOGISTIQUE} />,
      has_right_affaire_delete ? (
        <Tab label={TABS.SUPPRIMER} value={TABS.SUPPRIMER} />
      ) : null,
    ];
  }

  handleChangeTab(event, selectedTab) {
    const { history } = this.props;
    this.setState({ selectedTab });
    history.push({
      pathname: `/affaires/${selectedTab}`,
      state: { selectedTab },
    });
  }

  render() {
    if (this.props.facturation_cells) {
      return this.getAffairesList(TABS.COMMERCIAUX);
    }
    const { selectedTab } = this.state;
    return (
      <>
        <Tabs value={selectedTab} onChange={this.handleChangeTab.bind(this)}>
          {this.getAffairesTabs()}
        </Tabs>
        {this.getAffairesList(selectedTab ?? TABS.COMMERCIAUX)}
      </>
    );
  }
}

Affaire = withStyles(AffaireCss)(Affaire);
Affaire = withRouter(Affaire);

Affaire = connect((store) => {
  return {
    etablissementsStore: store.collections.etablissements,
    etatsStore: store.collections.etats,
    location: store.router.location,
    transportsStore: store.collections.transports,
    typeAffairesStore: store.collections.type_affaires,
    usersStore: store.collections.users,
    logisticiensStore: store.collections.logisticiens,
    user: store.auth.user,
    affairesStore: store.collections.affaires,
    campagnesTafStore: store.collections["campagne-tafs"],
  };
})(Affaire);

export default Affaire;
